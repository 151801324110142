

.button {
    border-radius: 30px;
    font-weight: 600;
    outline: 0;
    text-transform: uppercase;
    transition: all ease 250ms;

    &:hover {
        transition: all ease 250ms;
    }

    &.is-loading::after {
        border-radius: 50%;
        border: 2px solid $success;
        border-right-color: transparent;
        border-top-color: transparent;
    }

    &.is-dark.is-loading::after {
        border-color: transparent transparent $success $success !important;
    }

    &.m-header-sign-up-button {
        .m-header-account-sign-in & {
            background-color: $success;
            color:white;

            &:hover {
                background-color: darken($success, 1);

            }
        }
    }

    &.is-dark-green {
        background-color: $dark-green;
        color: white;
        border-color: $light-green;
        transition: all ease 250ms;

        &:hover,
        &.is-disabled {
            transition: all ease 250ms;
            background-color: $dull-green;
            border-color: $dull-green;
        }

        &.is-loading::after {
            border-color:  transparent transparent white white;
        }
        
    }

    &.is-dark-button {
        background-color: #111011;
        color: #ffdd19;
        border-color: #dbdbdb;
        border-width: 2px;
        transition: all ease 250ms;

        &:hover {
            transition: all ease 250ms;
            background-color: #5D5D5D;
            border-color: #dbdbdb;
            color: #ffffff;
        }
    }

    &.is-primary.is-outlined:not(.m-game-grid-button) {
        color: white !important;
        &:focus {
            color: $dark !important;
        }
    }
    &.is-primary.is-outlined.rtp {
        &:hover {
            color: black !important;
        }
    }
    &.is-full-width {
        width: 100%;
    }
    &.is-wide {
        min-width: 350px;
    }
    &.is-wide-desktop {
        @include from($desktop) {
            min-width: 350px;
        }
    }
    &.is-centered {
        margin-left: auto;
        margin-right: auto;
    }
    &.is-maintenance {
        height: auto;
        display: block;
        pointer-events: none !important;
        padding: 5px !important;

        i {
            @include from($tablet) {
                margin-right: 0;
            }
        }
    }
    &.is-xlarge-fullhd {
        @include from($fullhd) {
            border-radius: 100px;
            min-width: 350px;
        }
    }
    &.m-button-cta {
        border: 2px solid #7EFF00;
        display: block;
        max-width: 440px;
        line-height: 2.45;
        font-size: 69px;
        box-shadow: 0 0 30px #7EFF00;
        margin-top: 75px;
    }

    &.is-outline-dark {
        border-color: $dark !important;
        &:hover {
            background-color: darken($primary, 10%);
        }  
    }
    &.m-game-grid-button {
        .m-promo-carousel & {
            background-color: #111011;

            &:hover {
                background-color: $primary !important;
                color: #111011 !important;
            }
        }
    }
}

.react-cookie-banner {
    background-color: $brand-grey;
    .button-close {
        @extend .button;
        @extend .is-success;
        text-transform: uppercase;
    }
}

a.m-button-scroll-to-top,
.m-button-scroll-to-top {
    position: fixed;
    bottom: 60px;
    right: 20px;
    background: $black;
    color: $primary;
    border: 4px solid $primary;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    z-index: 99;
    i {
        font-size: 30px;
    }
    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
        bottom: 50px;
    }
}

.m-account-wrapper {
    .button {
        &.is-success {
            background-color: #111011;
            color: #ffdd19;
            border-color: #dbdbdb;
            border-width: 2px;
            transition: all ease 250ms;

            &:hover {
                transition: all ease 250ms;
                background-color: #5D5D5D;
                border-color: #dbdbdb;
                color: #ffffff;
            }
        }
    }
}

.button-disabled-resend {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none !important;
    opacity: 0.4 !important;
}

.m-bonus-preselector-button {
    border-radius: 100px;
    border-color: white !important;
    background-color: transparent;
    color: white;
    &:hover,
    &.is-disabled {
      background-color: rgba(255,255,255,0.15);
      color: white;
    }
    @include until($tablet) {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      min-height: 45px !important;
      margin-left: 1rem !important;
    }
    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
  }
  