.m-promo-nav {

    @include until($desktop) {
        .m-game-nav-menu.is-active {
            transform: translateX(-50%);
            left: 50%;
            top: 3.9rem;
            background-color: $brand-dark;
            
            a {
                color: $white;
                &.is-active,
                &:hover {
                    background-color: $white;
                    color: $brand-grey;
                }
            }
        }
        .level-left {
            margin: auto;
        }
    }
    
    
    .navbar-item {
        color: $primary;
        border: 0px solid;
        font-size: inherit;
        font-weight: inherit;
        outline: 0;
        cursor: pointer;

        @include until($desktop) {
            width: 100%;
        }
    }

    @include from($desktop) {
        font-weight: bold;
        margin-bottom: $gap*4;

        .navbar-item {
            color: $primary;
            &.is-active {
                background: transparent !important;
                color: $primary;
                text-decoration: underline;
            }
            &:hover,
            &:focus {
                color: $primary;
                text-decoration: underline;
                background: transparent !important;
            }
           
        }

    }

    .navbar-item {
        &[href*="promotions" i][href$="promotions/"],
        &[href*="aktionen" i][href$="aktionen/"]
        {
            text-decoration: underline;
        }
       
    }
        
        .has-active-items {

            .navbar-item {
               
                &:first-child {
                    text-decoration: none;
                    &.is-active {
                        text-decoration: underline;
                    }
                }
            }
        }

    .m-game-nav-button {
        line-height: 3rem;
        border: 0px solid;
        padding: 0 0 0 15px;
        border-radius: 5px;
        margin: 10px;
        background-color: $brand-yellow;
        cursor: pointer;

        &:focus {
            outline: 0;
        }
    }
}

.m-promo-article-layout {
    > .level-item {
        margin: $gap 0 $gap*2;
    }
}