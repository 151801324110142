.m-landing-page-hero {
  aspect-ratio: 12/5;
  margin: auto;
  background-size: 1920px 800px;
  background-position: center;
  position: relative;
  color: white;
  text-align: center;
  width: 100%;
  max-width: 1920px;

  canvas {
    margin: auto;
    max-width: 100%;
  }

  /*@media (hover: none) and (pointer: coarse) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
    }
  }*/

  @include until($tablet) {
    aspect-ratio: 12/8;
  }
}

@font-face { font-style: normal; font-weight: 300; }


.m-bg-glow {
  background-image: url('/images/bg-glow.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  position: relative;
}

.m-bg-greenery {
  background-image: url('/images/bg-landing-page-footer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 0;
  bottom: 0;
  height: 853px;
  width: 100%;
  display: none;
}

.home-page-wrapper + .footer + .m-bg-greenery {
  display: block;
}

.m-landing-page-section {
  background-position: center top;
  background-repeat: no-repeat;
  text-shadow: 0 0 10px rgba(0,0,0,1);
  padding-bottom: 140px;
  padding: 3rem 1.5rem;

  @include until($tablet) {
    padding: 0 1rem;
  }

  .button {
    text-shadow: 0 0 0;
  }

}