.m-main-nav-menu {
  width: 100%;
  max-width: 550px;
  background-color: $brand-menu-bg;
  font-size: 19px;
  position: absolute;
  left: -100%;
  z-index: 9999;
  text-transform: uppercase;
  opacity: 0;
  transition: left ease 250ms, opacity ease 250ms;
  pointer-events: none;

  &__container {
    width: 100%;
    max-width: $container-max-width !important;
  }

  &.is-active {
    opacity: 1;
    left: 0;
    transition: left ease 250ms, opacity ease 250ms;
    pointer-events: auto;
  }

  @include until($tablet) {
    font-size: 18px;
  }

  &__item {
    text-transform: inherit;
    background-color: #232526;
    width: 100%;
    position: relative;

    &-href {
      > button {
        text-transform: uppercase;
      }
    }

    &-button {
      color: #a7a8a7;
      background-color: #232526;
      font-weight: 600;
      display: block;
      border: 0px solid;
      width: 100%;
      font-size: 19px;
      text-align: left;
      cursor: pointer;
      padding: 10px 40px;

      &:hover {
        color: #a7a8a7 !important;
      }

      &-subcolor {
        color: white !important;
        &:hover {
          color: white !important;
        }
      }

      @include until($desktop) {
        font-size: 18px;
        padding: 7px 20px;
      }

      // @include until($tablet) {
      //   font-size: 18px;
      //   padding: 10px 20px;
      // }
    }

    img {
      position: absolute;
      top: 23px;
      transform: translateY(-50%);
      left: 1rem;

      @include until($desktop) {
        max-width: 20px;
        margin: 0;
      }
    }
  }

  .m-search-input {
    &:focus-within {
      .icon {
        &.is-right {
          display: flex;
          cursor: pointer;
          margin-left: auto;

          &.loader {
            font-size: 10px;
            margin: 50px auto;
            text-indent: -9999em;
            width: 11em;
            height: 11em;
            border-radius: 50%;
            background: #ffffff;
            background: -moz-linear-gradient(
              left,
              #ffffff 10%,
              rgba(255, 255, 255, 0) 42%
            );
            background: -webkit-linear-gradient(
              left,
              #ffffff 10%,
              rgba(255, 255, 255, 0) 42%
            );
            background: -o-linear-gradient(
              left,
              #ffffff 10%,
              rgba(255, 255, 255, 0) 42%
            );
            background: -ms-linear-gradient(
              left,
              #ffffff 10%,
              rgba(255, 255, 255, 0) 42%
            );
            background: linear-gradient(
              to right,
              #ffffff 10%,
              rgba(255, 255, 255, 0) 42%
            );
            position: relative;
            -webkit-animation: load3 1.4s infinite linear;
            animation: load3 1.4s infinite linear;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
          }
          .loader:before {
            width: 50%;
            height: 50%;
            background: #ffffff;
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: "";
          }
          .loader:after {
            background: #0dc5c1;
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: "";
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          @-webkit-keyframes load3 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes load3 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    &::placeholder {
      color: $form-text-color;
    }

    .m-game-nav-search {
      &-field {
        position: initial;
        padding: 20px;
        @include until($tablet) {
          position: initial;
        }
      }

      #clearsearch {
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:active {
          outline: none;
        }

        &:hover {
          color: $grey-light;
        }
      }
    }

    .icon {
      height: 100%;
      font-size: 1.5rem !important;
      color: $grey-dark;
      pointer-events: initial;

      &.is-right {
        display: none;
      }

      @include until($tablet) {
        width: 2.5em;
        font-size: 1.2rem !important;
        left: 10px;
      }
    }

    .input {
      border-radius: 40px;
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHNpemVzPSIiIGNsYXNzPSJDbG9zZV9fSW1hZ2Utc2MtMThhZnA4NS0wIHN0eWxlc19fQ2xvc2VJY29uLXNjLXN0dzMzaS0xIGViT1VBcSBmYm1NTm8iPjxwYXRoIGZpbGw9IiMzNzNCNDUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjY0NiA1LjQ1MmEuNS41IDAgMDAwLS43MDdsLTEuMzkxLTEuMzkxYS41LjUgMCAwMC0uNzA3IDBMMTIgOS45IDUuNDUyIDMuMzU0YS41LjUgMCAwMC0uNzA3IDBMMy4zNTQgNC43NDVhLjUuNSAwIDAwMCAuNzA3TDkuOSAxMmwtNi41NDcgNi41NDhhLjUuNSAwIDAwMCAuNzA3bDEuMzkxIDEuMzkxYS41LjUgMCAwMC43MDcgMEwxMiAxNC4xbDYuNTQ4IDYuNTQ3YS41LjUgMCAwMC43MDcgMGwxLjM5Mi0xLjM5MWEuNS41IDAgMDAwLS43MDdMMTQuMDk4IDEybDYuNTQ3LTYuNTQ4eiIgY2xpcC1ydWxlPSJldmVub2RkIj48L3BhdGg+PC9zdmc+");
        background-size: 25px 25px;
        height: 25px;
        width: 25px;
        background-color: $grey-dark;
        position: absolute;
        right: 1.5rem;
        cursor: pointer;
      }

      @include until($desktop) {
        padding: 0 3.5rem;
        font-size: 1rem;
      }

      @include until($tablet) {
        font-size: 18px;
        height: 2em;
        line-height: 1em;
      }

      @media screen and (max-width: $desktop) and (orientation: landscape) {
        font-size: 18px;
        height: 2em;
        line-height: 1em;
      }
    }
  }
}

.m-main-nav-menu__item {
  &:nth-last-of-type(2) {
    padding-bottom: 14px;
  }

  > ul {
    overflow: hidden;
    //ion: height ease 250ms;
    background-color: $brand-menu-bg;
    // height: 0;

    a.m-main-nav-menu__item,
    button.m-main-nav-menu__item {
      &-button {
        padding-left: 50px;
        text-transform: capitalize;

        @include from($desktop) {
          padding-left: 70px;
        }

        @include until($tablet) {
          padding: 7px 0px 7px 40px;
        }
      }
    }
  }
  &.is-active {
    > button {
      color: $primary !important;
    }
    > ul {
      //transition: height ease 250ms;
      overscroll-behavior: contain;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.m-main-nav-menu__item,
.m-main-nav-menu_submenu__item {
  background-clip: content-box;
  padding-bottom: 0.5rem;
}

.m-main-nav-menu_submenu__item {
  &:first-of-type {
    padding-top: 0.5rem;
  }

  &:last-of-type {
    padding-bottom: unset;
  }
}

.m-main-nav-menu__parent {
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  transition: max-height 0.2s ease-out;

  @include until($tablet) {
    max-height: 65vh;
  }

  @media only screen and (max-height: 700px) {
    max-height: 60vh;
  }

  @media only screen and (max-width: 1050px) and (orientation: landscape) {
    max-height: calc(100vh - 230px);
  }

  > li {
    transition: max-height 0.2s ease-out;
    > button {
      text-transform: uppercase;
    }

    ul {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }
  }

  &.searching {
    max-height: 0 !important;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #000;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-yellow;
    border-radius: 5px;
    height: 50%;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($brand-yellow, 10%);
  }
}

// .m-main-nav-menu__submenu {
//   .m-main-nav-menu__item-button {
//     padding: 10px 40px;
//   }
// }
