@import './modals/modal-signup-v2';

.m-modal-v2 {
  background-color: $card-background-color;
  z-index: 99999;
  .modal-close {
    background-color: white !important;
    position: absolute;
    top: 10px;
    right: 10px;
    @include until($tablet) {
      display: none;
    }
  }
  .delete {
    background-color: white !important;
    display: none;
    @include until($tablet) {
      display: inline;
    }
  }
  .modal-content {
    width: 100%;
    max-width: 800px;
    max-height: none;
  }
  .modal-card-head {
    flex-direction: column;
    color: white;
    padding: 20px 10px;
    border-color: transparent;

    a {
      text-decoration: underline;
    }
  }

  .modal-card-body {
    //text-align: center;
    min-height: 390px;
  }

  .m-modal-footer-logos {
    width: 100%;
    font-size: 400%;
    @include until($tablet) {
      font-size: 200%;
    }
    .column {
      text-align: center;
    }
  }

  .modal-card-foot {
    border-color: transparent;
    padding: 20px 10px;
  }

  .buttons {
    margin-top: 60px;

    &.is-flex-direction-column {
      flex-direction: column;
    }
  }

  .field {
    margin-bottom: 20px;

    .switch {
      &:focus {
        outline: none;

        &+label::before,
        &+label:after {
          outline: 0 !important;
        }
      }
    }
  }

  .m-date-selects {
    padding-left: 10px;
  }

  .m-recaptcha {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  @extend #modal-signup-v2;
  #onFinish {
    margin: 0 auto;
  }

  #limit {
    font-size: smaller;
    color: #a6b4c1;
    margin-top: 20px;
  }
}