.m-top-nav {
	@extend .navbar;
	position: static;
	background-color: $brand-dark;
	min-height: 0;

	> .container {
		max-width: $container-max-width;
		margin: auto;
		min-height: 0;
		padding-left: $gap;
		padding-right: $gap;
	}

	&-container {
		position: relative;
		z-index: 9999;
		padding: 0 $gap 0 0;

		.fullscreen-mode & {
			i.fa-expand-arrows-alt {
				&:before {
					content: "\f2d2";
				}
			}
		}
	}

	&-item {
		@extend .navbar-item;
		min-height: $navbar-height;
		text-shadow: 0 2px #000000;
		transition: background-color ease 250ms;

		&:hover {
			transition: background-color ease 250ms;
		}

		@include until($desktop) {
			min-height: 0;

			&.is-active {
				background-color: $primary !important;
			}
		}

		i {
			margin-left: 4px;
		}
	}

	&-menu {
		display: flex;
		width: 100%;

		@include until($desktop) {
			overflow: auto;
		}

		.level {
			width: 100%;
		}

		.level-left {
			@include until($desktop) {
				display: block;
				width: 100%;
			}
		}

		@include until($desktop) {
			display: block;
			background-color: $brand-dark;
			left: -100%;
			right: 100%;
			position: fixed;
			top: 80px;
			z-index: 31;
			bottom: 0;
			transition: all ease 300ms;

			&.is-active {
				left: 0;
				right: 0;
				transition: all ease 300ms;
			}

			.navbar-item {
				padding: 20px;
				border-bottom: 1px solid $light;
			}
		}
	}

	&-brand {
		padding: 0;
		margin-right: 0 !important;

		img {
			width: 280px;
		}

		@include until($desktop) {
			padding: 0;
		}

		@include until(980px) {
			width: 150px;
			padding: 0;

			img {
				width: 150px;
			}

		}
		
		@include until(375px) {
			width: 110px;
			padding: 0;

			img {
				width: 110px;
			}

		}
	}

	&-buttons {
		.dropdown {
			margin-right: 0.5rem;
		}
	}

	.m-language-menu {
		@include until($desktop) {
			display: none;
		}
	}
}

.m-header-account-sign-in {
	@include until($tablet) {
		width: 100px;

		.button {
			padding: 0 15px;
		}
	}
	.field {
		margin-bottom: 0;
		margin-top: 0.75rem;
	}

	.input {
		font-size: 0.9rem;
	}

	.level {
		margin-bottom: 0;
	}
}

.m-game-login-area {
	.button {
		border: 2px solid $success;
		color: $success;
		font-size: 0.9em;
		&#login {
			background-color: white;
			&:hover {
				background-color: darken(white, 10%);
			}
		}
		&#modal-signup-button {
			color: white;
		}
		&.is-small {
			border-radius: 4px;
		}
	}
	.input {
		&.is-small {
			font-size: 0.9em;
			border-radius: 4px;
		}
	}
}

.m-balance {
	line-height: 1.2;
}

.mobile-sign-in {
	padding: 7px 10px 0;
	background: transparent;
	border: 0px solid;
	font-size: 1rem;
	cursor: pointer;
	color: white;
	
	i {
		font-size: 2.5rem;
	}
	p {
		margin: 3px 0;
	}
}
.m-navburger__wrapper {
	@include from($tablet) {
  	min-width: 170px;
	}
	@include until($tablet) {
		width: 100px;
	}
}
.nav-bar-burger-holder {
	padding-bottom: 3px;
	padding-left: 3px;

	.navbar-burger {
		width: 90px;
		height: 60px;

		@include until($desktop) {
			color: #ffdd19 !important;
		  }

		@include until(600px) {
			width: 40px;
			height: 40px;
		}
		
		&:hover {
			color: darken($primary, 10%);
		}
		span {
	
		left: 8px;
		border-radius: 3px;
		width: 40px;
		height: 6px;
		left: 0px;

		&:nth-child(1) {
			top: calc(50% - 60px/4);
		}

		&:nth-child(3) {
			top: calc(50% + 55px/4);
		}
	}
		&.is-active {
			span {
				&:nth-child(1) {
					top: calc(50% + -5px);
				}

				&:nth-child(3) {
					top: calc(50% + 5px);
				}
			}
		}
	}
}

.m-mobile-nav-icon {
	margin-right: 30px;
}
