.m-account-wrapper,
.m-page-wrapper {
    background: $content-bg-color;
    background-size: 100% auto;
    padding: 40px 0;

    .box {
        line-height: 1.4;
    }
}

.m-account-icons {

    .box {
        margin-left: 0;
        margin-right: 0;
        -webkit-justify-content: center;
        -webkit-box-pack: center; /* justify-content */
        margin-bottom: 20px;

        .columns {
            text-align: center;
            @include until(1386px) {

                max-width: 465px;
                margin: 0 auto;

            }
        }

    }

}

.m-account-auto-margin {
    margin: 0 auto;
}

.m-account-nav {
    .m-game-nav-menu {
        @include until($desktop) {
            background-color: $brand-dark;
        }
        .is-active {
            @include from($desktop) {
                background-color:$brand-grey!important;
                color: $primary !important;
            }
        }
    }
}

.bonus-tables {
    .content {
        .active-bonuses {
            td {
                vertical-align: middle;
            }

            th:last-child {
                min-height: 43px;
            }
        }
    }
}

[disabled].input,
.select select[disabled],
[disabled].textarea {
    opacity: 1;
    color: #d0d0d0;
}