#modal-signup-v2 {
  .modal-card-body {
    padding-top: 0;

    .columns {
      column-gap: 1rem;
      margin-bottom: 0;

      .column {
        padding: 0;

        input[type="search"] {
          padding-right: 1rem;
        }
      }

      &.verify {
        flex-direction: column;
        align-items: center;
      }
    }

    .resendCodeBtn {
      position: relative;
      width: 70%;
      display: flex;
      margin: 4rem auto 0 auto;
      justify-content: flex-start;

      span {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }

      @include until($tablet) {
        width: 100%;
        font-size: 1.1rem;
        margin-top: 2rem;
      }
    }
    
    @include until($tablet) {
      .buttons {
        gap: 1rem;

        .button {
          margin: 0 auto;
        }
      }
    }

    .m-language-select {
      height: 3.2em;

      .select,
      .ref-select {
        height: 100%;
      }
    }

    #errorMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}