// =========================================================================
// Important - Cascade Dependant Code
// =========================================================================

.grecaptcha-badge {
  bottom: 80px!important;
  visibility: hidden;
}

.custom-password-field {
  .icon-visibility {
    pointer-events: auto!important;
  }
}
