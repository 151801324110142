.m-carousel {
  opacity: 0;
  border-bottom: 8px solid $primary;
  font-weight: 300;
  @include from(980px) {
    margin-top: 10px;
  }
  &.m-carousel-short {
    height: 225px;
    min-height: 0;
    opacity: 1;
  }

  @include until(1920px) {
    min-height: 400px;
  }

  .container {
    margin: auto;
  }

  .carousel-cell {
    display: none;

    img {
      vertical-align: top;
    }

    @include until(1920px) {
      max-height: 400px;
    }


    &:first-child {
      display: block;
      visibility: hidden;
    }
  }

  // applied to parent when carousel js has loaded.
  &.flickity-enabled {
    opacity: 1;
    transition: opacity ease 500ms;

    &:before {
      display: none;
    }

    .flickity-viewport {
      @include until(1920px) {
        min-height: 400px;
      }
    }

    .carousel-cell {
      display: block;
      visibility: visible;
      height: 500px;
      position: relative;
      overflow: hidden;

      .m-carousel-logged-in & {
        height: 260px;
      }
    }

    &.has-video {
      .carousel-cell {
        @include from(1594px) {
          height: auto;
          max-height: none;
          aspect-ratio: 1920/500;
        }
      }
    }

    .container {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      display: flex;
    }

    .hero {
      max-width: $container-max-width;
      margin: auto;
      width: 100%;
    }
  }

  .flickity-page-dots {
    bottom: 25px;

    .dot {
      background-color: $dark;
      border:2px solid $primary;
      opacity: 1;

      &:only-child {
        display: none;
      }
      &.is-selected {
        background-color: $primary;
      }
    }
  }

  .hero-tag {
    font-weight: 600;
    font-style: italic;
    text-shadow: -1px -1px 0 green, 1px -1px 0 green, -1px 1px 0 green,
    1px 1px 0 green;
    color: #fff;
    text-align: center;
    width: 200px;
    transform: rotate(-10deg);
    font-size: 24px;
    position: absolute;
    left: 60px;
    line-height: 1;
    top: 17px;
  }

  .hero-title {
    font-size: 100px;
    font-weight: 900;
    color: white;
    margin-bottom: 20px;
    line-height: 100%;
    display: inline;

    @include until($widescreen) {
      font-size: 62px;
      font-size: 8vw;
      text-shadow: 0 3px 0 #001c45;
    }

    @include until($tablet) {
      font-size: 64px;
      font-size: 10vw;
    }

    &--secondary {
      font-size: 56px;
      margin-bottom: 0;

      @include until($widescreen) {
        font-size: 40px;
        font-size: 3.5vw;
        text-shadow: 0 3px 0 #001c45;
      }

      @include until($tablet) {
        font-size: 4.5vw;
      }
    }
  }

  .hero-sub-title {
    font-size: 52px;
    font-weight: 700;
    margin-top: 20px !important;
    padding: 0;
    display: inline;
    color: white;

    @include until($widescreen) {
      font-size: 25px;
      font-size: 5.5vw;
      text-shadow: 0 3px 0 #001c45;
    }

    @include until($tablet) {
      font-size: 6vw;
    }
  }

  .hero-body {
    padding-left: $gap * 4;

    @include until($desktop) {
      padding-left: 1.5rem;
    }

    > * {
      margin-bottom: 0;
    }
  }

  .hero-title-wrapper {
    line-height: 64px;
    margin-bottom: 11px;
    @include until($tablet) {
      line-height: 34px;
    }
  }

  .carousel-cell {
    background-size: contain;
    background-position: bottom center;
    width: 100%; /* full width */
    height: auto;

    &:before {
      content: '';
      display: block;
      padding-bottom: 25%;
    }

    @include from(1920px) {
      &:after {
        content: '';
        display: block;
        padding-bottom: 11%;
      }
    }

    img,
    video {
      @include until(1920px) {
        max-width: 1920px;
        height: 400px;
        position: absolute;
        right: 0;
        max-width: none;
      }
      position: absolute;
      width: 100%;
      display: block;
      top: 0;
      left: 50%;
      transform: translate(-50%);
    }

    img {
      width: auto;
      height: auto;
      @include from(1920px) {
        width: 100%;
        height: auto;
      }
      @include until(710px) {
        height: 400px;
      }
      @include until(690px) {
        height: 200px;
      }
    }

    video {
      
      @include from(1550px) {
        height: auto;
        width: 100%;
      }
      
      @include until(690px) {
        height: 200px;
      }
      height: 400px;
      width: auto;
    }
  }

  .hero-title-section {
    &__block {
      display: inline-block;
    }

    &__title {
      font-size: 2.3vw;

      @include from(1905px) {
        font-size: 42px;
      }

      @include until($desktop) {
        font-size: 1.5vw;
      }

      @include until($tablet) {
        font-size: 3.5vw;
      }
    }
  }

  .flickity-viewport {
    transition: height 0 ease;
  }
}

.m-carousel-logged-in {
  opacity: 1;
  min-height: 340px;
  background-color: $brand-grey;
  @include until($tablet) {
    min-height: 300px;
  }
  // applied to parent when carousel js has loaded.
  &.flickity-enabled {
    .flickity-viewport {
      min-height: 400px;
      height: 100% !important;
      @include until($tablet) {
        min-height: 300px;
      }
      .flickity-slider {
        transform: translateX(0) !important;
      }

      .carousel-cell {
        background-size: contain;
        background-position: bottom center;
        width: 100%; /* full width */
        border-bottom: 8px solid $brand-yellow;
        height: 100%;
        overflow: hidden;
        left: 0 !important;

        .carousel-bg-image {
          height: 400px;
          width:100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          @include until($tablet) {
            height: 300px;
          }

          img,
          video {
            height: 400px;
            display: inline-block !important;
            width:100%;

            @include until($tablet) {
              height: 300px;
            }
          }

          video {
            object-fit: cover;
          }
        }

        .container {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          display: flex;
          height: 400px;
          @include until($tablet) {
            height: 300px;
          }
          .hero {
            max-width: $container-max-width;
            margin: auto;
            width: 100%;
            @include until($tablet) {
              margin: 0;
            }
            &-body {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              padding: 1.5rem;

              @include until($tablet) {
                padding: 0;
              }

              .hero-title-wrapper {
                line-height: 2rem;


                @include until($desktop) {
                  line-height: 35px;
                }

                .hero-title {
                  font-size: 7vw;
                  font-style: italic;
                  font-weight: 900;
                  color: white !important;
                  margin-bottom: 20px;
                  line-height: 100%;
                  display: inline-block;

                  @include from($widescreen) {
                    font-size: 5.5rem;
                  }
                  @include until($tablet) {
                    font-size: 2.5rem;
                    width: 100%;
                    margin-bottom: 0;
                  }
                }

                .hero-sub-title {
                  font-size: 3.7vw;
                  font-weight: 700;
                  color: white !important;
                  margin-top: 20px !important;
                  padding: 0;
                  display: inline;

                  @include from($widescreen) {
                    font-size: 2.5rem;
                  }
                  @include until($tablet) {
                    font-size: 1.2rem;
                    width: 100%;
                  }
                }
              }

              p {
                text-align: center;
                margin: 1rem 0;
                @include until($tablet) {
                  font-size: 1.1rem !important;
                  margin: 0 0 0.5rem;
                }
              }

              button {
                @include until($tablet) {
                  font-size: 1rem !important;
                }
              }
            }
          }
        }
      }
    }
    .flickity-page-dots {
      bottom: 25px;
      margin-left: 0;

      .dot {
        background-color: $dark;
        border:2px solid $primary;
        opacity: 1;
        &:only-child {
          display: none;
        }
        &.is-selected {
          background-color: $primary;
        }
      }
    }
  }
}

.m-carousel-accounts {
  height: 225px;
  min-height: 0;
  background-size: cover;
  background-position: center;
  opacity: 1;
  background-color: $brand-dark;
  .container {
    background: transparent;
  }
}

.m-carousel-short {
  height: 225px;
  min-height: 0;
  background-size: cover;
  background-position: center;
  opacity: 1;
  background-color: $brand-dark;
  // background-image: url('../images/support/support-email-hero.jpg');

  .container {
    background: transparent !important;
  }
}
.m-carousel-banking {
  // background-image: url('../images/banking-hero.jpg');
}

.carousel-cell {
  background-color: $brand-dark;
  video {
    background-color: $brand-dark !important;
  }
}


.m-promo-carousel {

  @include until(690px) {
    min-height: 200px;
    &.m-carousel.flickity-enabled .flickity-viewport {
      min-height: 20px;
    }
    &.m-carousel.flickity-enabled .carousel-cell {
      min-height: 200px;
      height: auto;
    }
  }

  .carousel-bg-image {
    img {
      bottom: 0;
      top: auto;

      @include until(690px) {
        height: 200px;
      }
    }
  }

  .carousel-cell {
    @include until(690px) {
      height: 200px;
    }

    .m-hero-bonus-currency {
      font-weight: 600;
    }

    .m-hero-bonus-amount {
      font-size: 138px !important;
      margin-bottom: 1rem;
    }

    .m-hero-bonus-text {
      text-align: center;
    }
  }
}
