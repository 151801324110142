.m-notification-site-message {
  padding: 5px;
  border-radius: 0;
  &:not(:last-child) {
    margin-bottom: 0;
  }
  @include until($desktop) {
    position: static;
    margin-top: 82px;
    width: 100%;
    z-index: 31;
  }
  @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
    position: static;
  }
}

.m-maintenance-is-active {
  .m-notification-site-message {
    @include until($desktop) {
      margin-top: 80px;
    }
    @media screen and (min-width:320px) and (max-width:$desktop) and (orientation:landscape) {
      margin-top: 0;
    }
  }
  main.main-content-parent {
      margin-top: 0px;
  }
}