.m-user-dashboard {
  padding-bottom: 10px;
  .container.is-fluid {
    max-width: 1315px;
    margin-left: auto;
    margin-right: auto;
    padding: $gap * 4 $gap $gap * 4;
  }

  &.columns {
    margin-top: 0;
    padding: $gap * 2 $gap;
  }

  .column {
    position: relative;

    @include until($tablet) {
      &:nth-child(1) {
        order: 3;
      }
      &:nth-child(2) {
        order: 1;
      }
      &:nth-child(3) {
        order: 2;
      }
    }
  }

  .card {
    border-radius: 0;
    background-color: $primary;
    box-shadow: 0 0 transparent;
    height: 100%;
    position: relative;
    z-index: 1;
    color: $brand-dark;
  }

  .card-title {
    padding: $card-content-padding;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .level {
    width: 100%;

    @include until(1095px) {
      display: block;
      text-align: center;
    }
  }

  .level-left,
  .level-right {
    @include until(1095px) {
      width: 100%;
      display: block;
      text-align: center;
      margin-top: 0;
    }
  }
}

.m-user-dashboard-info {
  margin-bottom: 4px;
  border: 1px #dadbdc solid;
  hr {
    margin: 1em 0;
    background-color: $primary;
  }
  &.card {
    border-radius: 8px;
    border-color: $primary;
    background-color: $brand-dark;
    box-shadow: 0 0 transparent;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 0 5px;
  }

  .card-content {
    padding: $gap;
  }

  i {
    vertical-align: middle;
    font-size: 24px;
    margin-right: 5px;
  }

  .level {
    width: 100%;
  }
}
.m-user-dashboard-card {
  > .card-content {
    margin-left: $card-content-padding;
    margin-right: $card-content-padding;
    margin-bottom: 0;
    &:last-child {
      padding-top: 0;
    }
    @include from($fullhd) {
      .m-user-dashboard-double & {
        margin-left: $card-content-padding * 3;
        margin-right: $card-content-padding * 3;
      }
    }

    .m-promo-widget-item {
      .level-left {
        @include from($desktop) {
          width: 32% !important;
        }
        img {
          max-width: 150px;
        }
      }
    }
  }
  .card-image {
    max-width: 80%;
    max-height: 80%;
    color: inherit;
    margin-left: $card-content-padding;
    margin-right: $card-content-padding;
  }
}

.m-user-dashboard-double {
  &.columns {
    padding: 0;
  }

  .m-user-dashboard-card > .card-content {
    padding: 0.75rem 1.5rem 1.5rem;
  }

  .m-user-dashboard-card > .card-title {
    padding: 0.75rem 1.5rem 0;
  }
}

.m-user-dashboard-info.m-user-dashboard-history {
  height: auto;
}

.m-user-dashboard-card.m-user-dashboard-info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .card-content {
    @include from($fullhd) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
  }

  .card {
    height: auto;
  }

  .level-right {
    margin-top: 0;
  }
}

.m-dashboard-balance .card {
  margin-bottom: 4px;
}

.m-dashboard-balance .card + .buttons {
  margin-top: 16px;
}

.m-user-dashboard-loyalty .m-user-dashboard-info:first-child.card {
  padding: $gap;
  margin: 0 27px 20px 42px;
}

.m-user-dashboard-loyalty .card {
  padding: 0 5px;
  img { width: 20% }
}

.m-user-dashboard-loyalty > .card-content {
  padding-top: 10px;
}

.m-user-dashboard-bonus-claim {
  .m-user-dashboard-info-with-inline-inputs {
    .card-content {
      padding: 6px 10px;
    }
    input[type='text'] {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $grey;
      }
    }
  }
}

.m-user-inline-inputs {
  display: flex;
  justify-content: space-between;
  > * {
    min-width: 0;
    &.button {
      margin-left: 5px;
    }
    @include from($tablet) {
      margin: 0 5px;
    }
  }
}

.m-loyalty-images {
  position: absolute;
  img {
    position: absolute;
    bottom: 100%;
  }
}

.m-loyalty-status {
  font-size: 12px;
  font-weight: bold;
  color: black;
  line-height: 1.75;
  img {
    //filter: brightness(-100%);
    margin-left: -5px;
  }
}

.m-loyalty-logo {
  margin-top: -6px;
  margin-bottom: 7px;
}
