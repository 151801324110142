.accordions {
    .accordion {
        .accordion-header {
            .toggle {

                pointer-events: none;
                line-height: 40px;
                padding: 20px;

                &::before {
                    content: 'V';
                }
            }
        }

        strong {
            color: $content-text-color;
        }
    }
}

.accordions.m-accordions {
    margin-bottom: 2.5em;
    .accordion {
        margin-bottom: $gap;
        border-radius: 8px;
        background-color: $brand-dark;
        &.is-disabled {
            .accordion-header {
                &:hover {
                    background-color: $primary;
                    color: $brand-dark !important;
                    i, strong {
                        color: $brand-dark;
                    }
                }
            }
        }
    }

    .accordion-header {
        strong {
            color: $brand-dark;
        }
        &:hover {
            background-color: $brand-dark;
            color: $primary;
            transition: all ease 250ms;
            strong {
                color: $primary;
            }

            i    {
                color: $primary;
            }
        }
        h2 {
          padding-bottom: 0;
        }

    }

    .is-active:not(.is-disabled) {
        > .accordion-header {

            i {
                transform: rotate(-180deg);
                top: 31%;
                color: $brand-dark;
            }

             &:hover {
                 background-color: $brand-dark;
                 color: $brand-yellow;
                 transition: background-color ease 250ms;
                 border: 1px solid $primary;

                 i {
                     color: $brand-yellow;
                 }
             }

        }
    }

    .is-active {
        .accordion-body {
            max-height: 150em;
        }
        .field {
            &.m-field-checkbox {
                .icon {
                    margin-top: 3px;
                }
            }
        }
    }


    .accordion-header {
        border-radius: $accordion-header-radius !important;
        font-weight: 700;
        padding: 1em 1.25em;
        text-decoration: none !important;
        border: 1px solid transparent;

        @include until($desktop) {
            padding-right: 4em;
        }




        &.m-accordion-header-no-hover {
            color: currentColor;
            &:hover {
                color: currentColor;
                background-color: inherit;
            }
        }


        i {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            color: $brand-dark;
        }
    }
    .accordion-body {
        background-color: $brand-dark;
        border-color: transparent !important;
        color: $grey-light;
    }

    ul {
        list-style: none;
    }

    .content {
        form {
            ul {
                margin-left: 0;
            }
        }
        ul {
            list-style: disc;
        }
    }

    .columns {
        flex-wrap: wrap;

    }
}

.m-accordion-boxes {

    margin: $gap 0;

    .icon {
        color: $grey-light !important;
    }

}

    .m-accordion-box {
        margin-top: 0 !important;

        .level,
        .columns {
            margin-bottom: 0px;
            padding: 0.5em 1em;
            border-radius: 6px;
            height: 100%;

            &.is-editable {
                border: 2px solid $success;
            }

        }

        .level-item {
            flex: 1;
        }

        .field {
            flex-direction: column;
            align-items: flex-start;
        }

    }

    .m-accordion-box-label {

        flex: 0 1 145px;
        padding-right: $gap;

        @include until($desktop) {
            flex: 0 1 92px;
        }

         @include until($tablet) {
            margin-bottom: $gap;
        }
    }
