// =========================================================================
// Layout - Sections of Page
// =========================================================================

main {
  @include until($desktop) {
    padding-top: 100px;
  }
}

.main-content-parent {
  min-height: calc(100vh - 111px);
}

.main-content-parent-short {
  min-height: 200px;
  background-color: black;
}
.main-content-parent-short + .m-seo-accordion {
  background-color: black;
}

.home-page-wrapper {
  .m-seo-accordion {
    background-color: $brand-mid-grey;
  }
  @include until($tablet) {
    .main-content-parent {
      min-height: 200px;
    }
  }
  .main-content-parent {
    min-height: calc(100vh - 111px);
  }
}

.row {
  padding: 0 $gap;

  &-touch {
    @include until($desktop) {
      padding: 0 $gap;
    }
  }
}

.section {
  @include until($tablet) {
    padding: 0 $gap;
  }
}

.m-grid-item {
  margin: $gap/2;
  overflow: hidden;

  > img {
    vertical-align: top;
    width: 100%;
  }
}

.section {
  > .container.is-fluid {
    max-width: $container-max-width !important;
    margin: auto;
    padding: 0 $gap;
  }
}

.m-content-top-padding {
  padding-top: $gap * 2;
}
.m-content-top-padding-x2 {
  padding-top: $gap * 4;
}

.m-page-width-wrapper {
  max-width: 840px;
}

.restricted-country-box {
  .content {
    p {
      text-align: center;
    }
  }
}

.not-available-page {
  padding-top: 100px;
  min-height: calc(100vh - 200px);

  .notification {
    min-height: calc(100vh - 200px);
  }
}

.m-logo-holder {
  aspect-ratio: 4/3;
  > div {
    aspect-ratio: 4/3;
    background-color: #222222;

    display: flex;
    align-items: center;
  }
}

.is-gameprovider-list {
  #schemas {
    display: none;
  }
}

main.custom-main {
  padding-top: 0px;
}

.skeleton-loading {
  background-color: #e2e5e71f;
	// The shine that's going to move across the skeleton:
	background-image:			
			linear-gradient(
				90deg, 
				rgba(#fff, 0), 
				rgba(#fff, 0.04),
				rgba(#fff, 0)
			);
	background-size: 40px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	animation: shine 2s ease infinite; // increase animation time to see effect in 'slow-mo'

  padding-bottom: 69.5%;
    position: relative;
    overflow: hidden;
    height: 100%;
}
@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}

.skeleton {
  gap: 0.2rem;
}

#loader-grid {
  grid-gap:0.4rem
}