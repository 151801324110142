.datetimepicker-wrapper.modal {
  opacity: 0;
  transition: opacity ease 200ms;

  &.is-active {
    opacity: 1;
    transition: opacity ease 300ms;
  }
}

.modal .datetimepicker {
  border-radius: 5px;
}

.datetimepicker.is-primary .datepicker-nav {
  border-radius: 5px 5px 0 0;
}

.m-control-calendar {
  position: relative;
  height: 3.2em;

  .icon {
    font-size: 1.25em;
  }

  .m-dummy-input-container {
    position: absolute;
    width: 100%;
    pointer-events: none;
  }

  .m-date-selects {
    width: 100%;
    padding: calc(0.5em - 2px) 3.25em calc(0.5em - 1px) 2.5em;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .select {
      padding-right: 0em;
      background-color: transparent;

      @include until(760px) {
        font-size: 0.9em;
      }
    }

    .select select:not([multiple]) {
      background-color: transparent;
      padding-right: 1.85em;

      @include until(500px) {
        padding-right: 1em;
      }
    }

    .select:not(.is-multiple):not(.is-loading)::after {
      right: 0.5em;
      @include until(500px) {
        right: 0;
      }
    }

    .control {
      margin-right: 0 !important;

      @include until(500px) {
        font-size: 0.8em;
        text-align: right;
      }
    }

    .m-select-seperator {
      margin-left: 3px !important;
      color: $grey-light;
    }
  }
  .datepicker {
    overflow: visible;

    .datepicker-nav-month {
      position: relative;
      text-align: center;

      &:hover {
        color: $light;

        &:before,
        &:after {
          background: $grey;
          color: white;
        }
      }

      &:before {
        content: attr(data-month-text);
        display: block;
        position: absolute;
        top: -39px;
        z-index: 1;
        transform: translateX(-75%);
        padding: 5px 10px;
        font-size: 0.8rem;
        background: white;
        color: black;
        border-radius: 10px;
        min-width: 110px;
      }

      &:after {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background: white;
        position: absolute;
        top: -30px;
        transform: translateX(-30%) rotate(45deg);
      }
    }

    .datepicker-nav-year {
      text-align: center;

      &:hover {
        color: $light;

        &:before,
        &:after {
          background: $grey;
          color: white;
        }
      }

      &:before {
        content: attr(data-year-text);
        display: block;
        position: absolute;
        top: -30px;
        z-index: 1;
        transform: translateX(-7%);
        padding: 5px 10px;
        font-size: 0.8rem;
        background: white;
        color: black;
        border-radius: 10px;
        min-width: 110px;
      }

      &:after {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background: white;
        position: absolute;
        top: -20px;
        transform: translateX(25%) rotate(45deg);
      }
    }
  }

  .datetimepicker-dummy
    .datetimepicker-dummy-wrapper
    .datetimepicker-dummy-input {
    max-width: 100%;
    height: auto;
  }

  .datetimepicker-dummy {
    height: 2.3rem;

    &:before {
      top: 0.55rem;
    }

    .datetimepicker-clear-button {
      top: -3px;
    }
  }

  .datetimepicker .datepicker-nav .datepicker-nav-year {
    font-weight: 500;
  }

  .datetimepicker-wrapper.modal.is-active {
    overflow: visible;
  }
}

.datetimepicker .datepicker-nav .datepicker-nav-next,
.datetimepicker .datepicker-nav .datepicker-nav-previous {
  color: $brand-dark;

  &:active,
  &:focus {
    background-color: transparent !important;
  }
}

.datetimepicker-dummy .datetimepicker-clear-button {
  top: 3px;
  color: $brand-dark !important;
}
.datepicker-body
  .datepicker-dates
  .datepicker-days
  .datepicker-date
  .date-item.is-today {
  border-color: $brand-dark !important;
  color: $brand-dark !important;
}
.datetimepicker.is-primary
  .datepicker-body
  .datepicker-dates
  .datepicker-days
  .datepicker-date.datepicker-range.datepicker-range-end
  .date-item {
  color: $primary !important;
}
.datetimepicker-footer {
  display: none !important;
  overflow: hidden;
  .datetimepicker-footer-cancel {
    display: none;
  }
  overflow: hidden;
}

.modal .datetimepicker {
  overflow: visible;
}

.datetimepicker.is-datetimepicker-default .datetimepicker-container:before {
  display: none;
}

.datetimepicker-container {
  &:after {
    display: none;
  }
}

.datetimepicker-dummy .datetimepicker-dummy-wrapper {
  @extend .input;
  padding-right: 2.25em;
  font-size: 1.25rem;
  background: transparent;
  height: 2.5em;
}

.datetimepicker-dummy {
  @include until($desktop) {
    height: auto;
  }
  &:before {
    display: none;
  }
}

.datetimepicker-dummy
  .datetimepicker-dummy-wrapper
  .datetimepicker-dummy-input:first-child {
  text-indent: 2rem;
  font-size: 1.2rem;
}

.m-dob-control {
  position: relative;
  top: -36px;
}

.m-date-range-picker {
  position: relative;

  .datetimepicker-footer {
    display: flex !important;

    .has-text-warning {
      color: $primary !important;
    }
  }
}

.m-date-range-picker-container {
  @include until($tablet) {
    margin-bottom: -$gap;
  }

  .datetimepicker-wrapper {
    display: block !important;
    overflow: visible;
  }

  .datetimepicker {
    z-index: 10;
    position: absolute;
    top: 97px;
  }

  .datetimepicker-dummy
    .datetimepicker-dummy-wrapper
    .datetimepicker-dummy-input:first-child {
    font-size: initial;
    text-indent: 0;
  }

  .datetimepicker-dummy {
    display: block !important;
  }

  .datetimepicker-dummy-wrapper {
    background-color: white !important;
  }

  .modal.is-active:not(#modal-cookie-popup) {
    background-color: transparent;
    backdrop-filter: none;
  }
}

.m-date-range-picker-container .datetimepicker-dummy-wrapper,
.m-date-range-picker-container .datetimepicker-dummy-wrapper:hover {
  background-color: #111011 !important;
  border: 1px #ffdd19 solid;
  border-radius: 8px;
}

.m-date-range-picker-container .datetimepicker-dummy-wrapper .datetimepicker-dummy-input {
  color: #ffdd19 !important;
}

#daterangebutton {
  background-color: #111011;
  color: #ffdd19 !important;
  border-color: #dbdbdb;
  border-width: 2px;
  transition: all ease 250ms;
}

#daterangebutton .icon {
  color: #ffffff;
}

#daterangebutton:hover,
#daterangebutton:focus,
#daterangebutton:active {
  background-color: #5D5D5D;
  color: #ffffff !important;
}
