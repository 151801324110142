.m-thumbnail-carousel {
  position: relative;
  height: 158px;
  margin-top: 50px;
  @include until($tablet) {
    transform: scale(0.55) translateX(-200px);
    margin-top: -33px;
  }
}

.m-thumbnail-carousel__thumbnails {
  position:absolute;
  width:calc(100vw + 940px);
  max-width: 1920px;
  margin: 0 auto;
  height:308px;
  left:50%;
  overflow:hidden;
}

.m-thumbnail-carousel__thumbnail {
  position:absolute;
  width: 368px;
  height:246px;
  border: 2px solid black;
}