// =========================================================================
// State - JS Dependant
// =========================================================================

// =========================================================================
// JS Classes - prefixed with .js-
// =========================================================================

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-1000px) scale(0.7, 0.7);
  }
  to {
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
}

.start {
  transform: translateY(-294px);
  opacity: 0;
}

.fade-in-element {
  opacity: 1;
  transform: translateY(0);
  transition: all ease-in 250ms;
  transition-timing-function: cubic-bezier(0.27, 0.33, 0.5, 1.5);
}

.is-clipped {
  height: 100%;
}
