.m-game-nav {
  line-height: $navbar-height;
  padding: 0 $gap;

  &.is-fluid {
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;

    @include until($tablet) {
      padding: 0 $gap/2 0 $gap/2;
    }

    > .level {
      @include until(375px) {
        justify-content: space-between;
      }
    }
  }

  &-parent.is-active {
    margin-bottom: 50px;
  }

  .navbar-item {
    color: $brand-dark;
    transition: background-color ease 250ms;
    background: transparent;
    border: 0px solid;
    font-size: inherit;
    @include until(1305px) {
      font-size: 13px;
      padding-left: $gap/2;
      padding-right: $gap/2;
    }
    @include until($desktop) {
      font-size: inherit;
    }
    &:hover {
      background-color: $navbar-item-active-background-color;
      transition: background-color ease 250ms;
      color: white;

      @include until($desktop) {
        background-color: $light;
        color: $brand-dark;
      }
    }
  }

  .level-right {
    align-items: center;
    line-height: $navbar-height;
  }

  &-button {
    .navbar-burger {
      pointer-events: none;
    }

    @include until($tablet) {
      padding: 0 0 0 5px;

      .navbar-burger {
        width: 2.5rem;
      }
    }
  }

  &-menu {
    border: 0px solid;
    border-radius: 0;

    > .navbar-item {
      @include until(1070px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @include until($desktop) {
      display: none;
    }

    &.is-active {
      @include until($desktop) {
        .active {
          &.navbar-item.is-active:not(:focus):not(:hover) {
            background-color: white;
            color: $brand-dark;
          }
        }
        display: block;
        position: absolute;
        top: $navbar-height;
        width: 200px;
        background: $brand-grey;
        box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
      }

      @include until($desktop) {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
      }
    }

    &-label {
      line-height: $navbar-height;
      color: $brand-dark;
    }

    .navbar-item {
      @include until($desktop) {
        padding: 0.375rem 1rem;
        color: white;
      }
    }
  }

  &-search {
    line-height: $navbar-height;
    overflow: hidden;

    .m-game-nav-search-field {
      @include until($desktop) {
        position: absolute;
        right: -100%;
        padding: $gap;
        transition: all ease 300ms;
        top: $navbar-height;
        z-index: 31;

        &.is-active {
          top: $navbar-height;
          background: $primary;
          width: 100%;
          right: 0;
          transition: all ease 300ms;
        }

        &.is-inactive {
          height: 0;
          width: 0;
          overflow: hidden;
          position: fixed;
        }
      }
    }

    &-button {
      line-height: $navbar-height;
      padding-top: 0;
      padding-bottom: 0;
      background-color: $brand-grey;
      border-color: $brand-dark;
      color: $brand-dark;
    }

    .input {
      color: $light;
    }
  }

  &-secondary {
    &.navbar-item {
      &:hover {
        background-color: transparent;
      }

      .navbar-item {
        padding: 0.375rem 1rem;
        color: white;
        &.active {
          background-color: white;
          color: $brand-grey;
        }
      }
    }
    .m-game-nav-menu {
      &.is-active {
        .navbar-item {
          color: $brand-dark;
          &.is-active.active {
            color: $brand-dark;
          }

          &:hover {
            &.is-active.active {
              color: $brand-dark;
            }
          }
        }
      }

      @include until($desktop) {
        right: 0;
        left: auto;
      }
    }
  }
}

.m-top-nav-sub-area .box .button {
  vertical-align: baseline;
}

.m-game-search-field {
  background-color: $brand-dark;
  &::placeholder {
    color: $primary;
  }
}

.m-game-nav-button {
  color: $brand-dark;
  &:hover {
    background: transparent;
  }
}

.m-top-nav-sub-area.m-top-nav {
  min-height: 55px;
  @include until($desktop) {
    position: fixed;
    top: 78px;
    left: 0;
    right: 0;
  }
}

.m-top-nav-game-menu {
  @include from($desktop) {
    display: flex;
  }
  .m-top-nav-item {
    &:first-of-type {
      @include from($desktop) {
        margin-left: 170px;
      }
    }
  }
}

.m-game-omni-logo {
  background-color: $primary;
  padding: 15px 5px 15px 10px;
  position: absolute;
  top: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

button.m-game-nav-button {
  background-color: transparent;
  border: 0px solid transparent;
  align-items: center;
  line-height: 0;
  outline: 0;
  outline: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: inherit;
  cursor: pointer;
}

.m-game-nav-secondary {
  .m-game-nav-menu.is-active {
    display: block;
    position: absolute;
    top: $navbar-height;
    width: 200px;
    background: $brand-grey;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    .navbar-item {
      &:not(.active) {
        color: white;
      }

      &:hover {
        color: $brand-grey;
      }
      &.active {
        background-color: white;
        color: $brand-grey;
      }
    }
  }
}

.m-game-nav-bar {
  padding: 6px 0;
  .container {
      max-width: 1920px !important;
      padding-right: 0;
      padding-left: 25px;

      @include until($desktop) {
          padding-right: 20px;
          position: relative;
      }

      @include until($tablet) {
          padding-left: 10px;
          padding-right: 5px
      }
  }
  .switch {
      height: 0;
      line-height: inherit;
  }

  .field {
      display: flex;
      align-items:center;
      color: $primary;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      border: 0px solid;



      p {
          font-size: 18px;
          font-weight: 600;

      }
  }

  .switch[type="checkbox"].is-small + label {
      height: 1.5em;
      line-height: 1.5em;
      margin: 0px 0px 0 7px;
      pointer-events: none;

      &::before       {
        background-color: $primary !important;
      }
      &:after {
        background-color: $brand-dark !important;
      }
  }

  &--real-mode {
    .m-game-tools {
      background-color: black;
      border-radius: 20px;
      padding: 5px;
      margin-right: 20px;
      button {
        color: $primary;
      }
    }
  }
}