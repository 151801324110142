.m-logo-carousel {
  position: relative;
  z-index: 1;
  height:215px;
  pointer-events: none;
  @include until($tablet) {
    margin-top: 90px;
    display: none;
  }
}

.m-logo-carousel__logos {
  position:absolute;
  width: 1920px;
  height:440px;
  left:50%;
  overflow:hidden;
  opacity: 0;
  padding-top: 175px;
  transform: translate(-50%, -50%) !important;
  background: url('/images/hero-curves-double.png') no-repeat;
  background-size: 1920px 440px;
}

.m-logo-carousel__logo {
  position:absolute;
  width: 240px;
  height:160px;
  padding:25px;
  font-size:40px;
  font-weight:600;
  line-height:60px;

  img {
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 150px;
  }
}