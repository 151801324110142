.m-modal-base {
  .m-modal-close-button {
    @include until($tablet) {
      display: none;
    }
    position: absolute;
    top: -20px;
    right: -20px;
    background: $primary;
    border: 0px solid;
    font-size: 40px;
    padding: 0;
    line-height: 0;
    border-radius: 40px;
    cursor: pointer;
    text-align: center;
    width: 41px;
    height: 41px;
    outline: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      width: 41px;
      height: 41px;
      border: 4px solid $primary;
      border-radius: 41px;
      z-index: 2;
      transition: all ease 100ms;
    }

    i {
      position: relative;
      z-index: 1;
      margin: 1px 0 0 1px;
    }
    transition: all ease 100ms;

    &:hover {
      background-color: darken($primary, 10%);
      transition: all ease 100ms;
      &:after {
        border-color: darken($primary, 10%);
        transition: all ease 100ms;
      }
    }
  }

  .modal-card-title {
    font-weight: 700;
  }

  &.m-modal-full-height {
    .modal-card-head {
      padding-top: $gap * 4;
      padding-bottom: 0;
      .modal-card-title {
        display: none;
      }
    }
    .modal-card-foot {
      padding-top: $gap * 2;
      padding-bottom: $gap * 2;
    }
    .modal-card,
    .content {
      @include from($tablet) {
        min-height: calc(100vh - 220px);
      }
      min-height: 475px;
      max-height: none;
      display: flex;
    }

    .modal-card-body {
      @include until($tablet) {
        border-radius: 0;
      }
    }
  }
  &.modal.is-active.m-modal-fixed--mobile {
    overflow: auto;

    @include until($tablet) {
      display: block;
      overflow: auto;
      .modal-card-body {
        overflow: hidden;
        overflow-x: auto;
      }
      .modal-content {
        max-height: none;

        @include until($tablet) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .modal-card-body {
        .content {
          overflow-x: auto;
        }
      }
    }
  }

  &.modal.is-active.m-modal-fixed {
    display: block ;
    &#modal-bambora-cashier {
      height: 100%;

      .modal-card-body {
        justify-content: flex-start!important;
      }

      .delete {
        @include from($tablet) {
          display: none;
        }
      }

      .modal-card-head {
        padding: 0.75rem 2rem;

        .m-cashier-controls {
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          @include until($tablet) {
            justify-content: center;
            gap: 0;
          }

          .m-cashier-balance-container {
            max-width: 400px;
          }
        }

        @include until($desktop) {
          padding-right: 4rem;
        }

        @include until($tablet) {
          padding: 0.75rem 2.5rem 0.75rem 1rem;
        }


      }

      .modal-card.modal-content {
        height:100%;
        overflow: initial;
        padding-top: 3rem;

        @media screen and (min-width: 850px) {
          height: 97%;
        }

        @include until($tablet) {
          padding:0;
        }

        .m-modal-external-message {
          display: none;

          &:last-child {
            display: none !important;
          }
          @include until($tablet) {
            height: 4rem;
            padding: 1rem .5rem;
            background: #000;
          }
        }
        .m-user-dashboard-info.card {
          z-index: 0;

          .level-item {
            @media only screen and (max-width: 350px) {
              h3 {
                font-size: 1rem !important;
              }
            }
          }
        }
        .modal-card-body {
          background: #000;
          padding:0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          -webkit-overflow-scrolling:touch;
          min-height: initial;

          #cashier-deposit-content {
            flex: 1;
            min-height: initial;
            width: 100%;

            .m-tab-panel.is-active {
              align-self: stretch;
              height: initial !important;

              #cashier-deposit {
                @include from($tablet) {
                  display: block;
                  position: initial;
                  height:100%;
                  overflow-y:hidden;

                  iframe {
                    min-height: initial !important;

                    .show-more-methods-btn {
                      padding: 20px 0;
                    }
                  }
                }
              }
            }

            &::-webkit-scrollbar {
              width: 0;
              background: transparent;
            }
          }
        }

        @media screen and (max-height: 1000px) {
          .modal-card-foot {
            display: none;
          }
        }

        @media screen and (max-width: 1200px) {
          .modal-card-foot {
            display: none;
          }
        }

        @media screen and (max-width: 850px) and (orientation: landscape) {
          padding-top: 0;
          height: 100%;
          min-height: 550px;

          .modal-card-head {
            padding: 10px 50px;

            .columns {
              margin: 0;
            }
          }
          .modal-card-foot {
            display: none;
          }

          .m-modal-external-message {
            display: none !important;
            background: #000;
            height: 3rem;
            padding-top: 0.5rem;

            &:last-child {
              display: none !important;
            }
          }

          .modal-card-body {
            min-height: 175vh;

            .middle-loader {
              display:block;
              padding-top: 2rem;
            }

            #cashier-deposit-content {
              .m-tab-panel.is-active {
                height: auto;

                #cashier-deposit {
                  position: initial;
                  height: 100%;
                  overflow-y: hidden;
                }
              }
            }
          }
        }

        .m-payment-method {
          background: black;
        }

      }
    }

    &#modal-signin {
      overflow-y: auto;

      .modal-card {
        &.modal-content {
          overflow-y: auto;

          .modal-card-head {
            button {
              background-color: white;

              @include from($desktop) {
                max-height: 30px;
                max-width: 30px;
                height: 100%;
                width:100%;
              }
            }
          }

          .modal-card-body {
            overflow: initial;
          }

          .modal-card-foot {
            .column {
              &.is-hidden-mobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .m-modal-external-message {
      @media screen and (max-height: 800px) {
        display: flex;
        align-items: center;
        padding: 15px 20px;
      }

      @media screen and (orientation: landscape) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      i {
        margin-bottom: 0;
      }
    }

    .modal-card {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      @include until($tablet) {
        margin: 0;
        width: 100%;
      }
    }
  }

  .modal-card-body {
    padding-bottom: 40px;
    overflow: hidden;
  }

  &.modal.is-active.m-modal-fixed {
    position: fixed;
    overflow-y: auto;
    display: block;

    @include from($widescreen) {
      display: block;
    }

    .modal-background {
      position: fixed;
      z-index: -1;
      pointer-events: none;
    }

    .modal-content {
      max-height: none;

      @include until($tablet) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .modal-card-body {
      .content {
        overflow-x: auto;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .m-field-radio {
    padding: 0.5em;
  }

  .m-field-checkbox {
    padding: 0;
    margin: 0;
  }

  .modal-card-head,
  .modal-card-body {
    @include until($tablet) {
      padding: 1.5em;
    }
  }

  .modal-card-title {
    .m-modal-signup & {

      @include from($tablet) {
        margin-right: $gap * 3;
      }
    }
  }

  .modal-card-body {
    > .columns {
      > .column:first-of-type {
        padding: 0 $gap * 2 0 0;

        @include until($tablet) {
          padding: 0 0 $gap * 2 0;
        }
      }
    }
  }

  .modal-card-foot {
    .columns {
      width: 100%;
    }
  }

  .modal-content,
  .modal-card {
    @include until(800px) {
      width: 100%;
    }
  }

  .steps .steps-content {
    margin: 0;
  }

  .steps-actions {
    margin-bottom: 0;
  }

  .notification {
    .media-right {
      align-self: flex-end;
      padding-bottom: 14px;
    }
  }
}

.modal-card-head {
  .columns {
    width: 100%;
  }

  @include until($tablet) {
    padding: 20px 50px;

    .delete {
      display: inline;
      position: absolute;
      top: $gap * 2;
      right: $gap * 2;
      background: white;
    }
  }

  #modal-bambora-cashier & {

    @include until($tablet) {
      padding: 20px 50px 0 20px;

      .delete {
        display: inline;
        position: absolute;
        top: $gap * 2;
        right: $gap * 2;
        background-color: white;
      }
    }

    @include until($tablet) {
      padding: 20px 40px 0px 20px;

      .delete {
        right: $gap;
      }
    }
  }
}

.modal {
  &.is-active {
    &:not(#modal-cookie-popup) {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(6px);
    }
  }

  .modal-background {
    background-color: transparent;
  }
  .modal-card-title {
    flex-shrink: 1;

    @include until($tablet) {
      padding-right: $gap * 3;
    }
  }

  &#modal-signup {
    .modal-card-title {
      @include until($tablet) {
        padding-right: 0;
      }
    }
    .notification {
      padding-right: 1.5rem;
    }
  }

  @include until($desktop) {
    .modal-card-foot,
    .modal-card-head {
      padding: 20px;

      .delete {
        background-color: white;
      }
    }
  }
}

.info .icon {
  vertical-align: middle;
}

.m-modal-password-reset {
  @include until($desktop) {
    .columns {
      flex-direction: column;
    }
  }
  .is-full-width {
    width: 100%;
  }

  .is-vertical-middle {
    vertical-align: middle;
  }
}

.m-modal-contents-fixed-bottom {
  pointer-events: none;
  z-index: 9999;
  .modal-content {
    pointer-events: auto;
    position: fixed;
    bottom: 0;

    @include until($desktop) {
      bottom: 0;
      margin: 0;
      left: 0;
      right: 0;
      width: auto;
    }
  }

  &.has-notification {
    overflow: visible;

    .modal-content {
      width: 100%;
    }

    .notification {
      width: 100%;
      border: 0px solid;

      @include until($tablet) {
        padding: $gap $gap * 2;

        .media-left {
          margin-right: 0;
        }

        .media-right {
          margin-left: 0;
        }
      }
      .media {
        max-width: 800px;
        margin: auto;
        @include until($tablet) {
          display: block;
          text-align: center;

          .media-content {
            text-align: center;
          }
        }
      }
    }
  }
}

.deposit-limit-error-box{
  background-color: white;
  padding: 2rem;
  gap: 2rem;
  a{
    text-decoration: underline;
  }
}

#modal-signup,
#modal-signin {
  display: flex;

  .label,
  label {
    color: $content-text-color !important;
  }
  a {
    color: $content-text-color;
    &:hover {
      text-decoration: underline;
    }
    &.button {
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .modal-card-title {
    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .select select option {
    color: $dark !important;
    background: white;
  }
}

.notification-close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  //@extend .delete;
}

.middle-loader {
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: auto;
  }
}

.connection-pop-up {
  position: fixed;
  top: 0;
  right: 20px;
  top: 12px;
  font-size: 0.75rem;
  z-index: 99;

  &.no-error {
    .fas {
      color: green;
    }
  }

  @include until($tablet) {
    right: 0;
  }

  .fa-slash {
    margin-left: -20px;
  }
}

.modal-confirm {
  .modal-card-body {
    padding: 5px;

    .notification {
      background-color: #721514;

      &.is-success {
        background-color: #015802;
      }
    }
  }
}

#modal-signup {
  .is-checkradio[type='checkbox'] + label:first-of-type {
    display: block;
  }
  .modal-card-title {
    small {
      display: block;
      line-height: 1.8;
    }
  }
  .modal-card {
    @include until(860px) {
      padding: 20px;
    }
    @include until($tablet) {
      padding: 0;
    }
    @include from($tablet) {
      overflow: visible;
    }
  }
  .modal-card-body {
    padding: 20px 20px 0;
    flex-shrink: 0;
    transition: all ease 100ms;
    > .columns {
      > .column {
        padding: 0.3em;
      }
    }
  }
  .m-fieldset {
    > .columns {
      > .column {
        padding: 0 0.4em;
      }
    }
  }
  .m-field-checkbox {
    padding: 0 0.4em;
  }
  a {
    color: $content-text-color;
    text-decoration: underline;
  }
  .modal-card-foot,
  .modal-card-head {
    padding: 25px 50px;
  }
  .modal-card-head {
    padding: 35px 50px 40px;
  }
  .modal-card-foot {
    padding: 25px 50px;
    min-height: 100px;
    @include until($tablet) {
      padding: 25px;
    }
    .level {
      width: 100%;
    }
  }
}

#modal-email-support-confirm {
  .modal-card-head {
    button {
      background-color: white;

      @include from($desktop) {
        max-height: 30px;
        max-width: 30px;
        height: 100%;
        width:100%;
      }
    }
  }

  .modal-card-foot {
    display: none;
  }
}

.m-modal-external-message {
  padding: 10px 0;
  font-size: 13px;
  @include until(720px) {
    line-height: 1.2;
  }
  i {
    vertical-align: middle;
    margin-right: 10px;
    color: white;
    @include until(720px) {
      margin-right: 10px;
      color: white;
      margin-bottom: 30px;
      display: inline-block;
      line-height: 0.5;
      vertical-align: top;
      margin-top: 6px;
    }
  }
  br {
    @include from($tablet) {
      display: none;
    }
  }
  small {
    text-shadow: 0 0 8px rgba(0, 0, 0, 1);
    color: white;
    max-width: 80%;
    vertical-align: middle;
    display: inline-block;
    @include until(720px) {
      text-align: left;
    }
  }

}

@media screen and (orientation:landscape) {
  #modal-error {
    &.is-active {
      .modal-card-head {
        padding: 20px;

        button {
          &.m-modal-close-button {
            &::after {
              background: $primary;
            }
          }
        }
      }

      .modal-card-body {
        padding: 4rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .notification {
          width: 100%;
        }
      }

      .modal-card-foot {
        padding: 17px;
      }
    }
  }
}

@media screen and (min-width: 710px) and (max-width: 850px) {
  .m-modal-base {
    .m-modal-close-button {
      display: none;
    }
  }

  #modal-bambora-cashier .modal-card-head .delete {
    display: inline !important;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
  }
}

@media screen and (min-width: 851px) and (max-width: 860px) {
  .m-modal-base {
    .m-modal-close-button {
      display: block;
    }
  }

  #modal-bambora-cashier .modal-card-head .delete {
    display: none;
  }
}

@media screen and (max-width: 850px) and (orientation: landscape) {
  #modal-bambora-cashier {
    .modal-card-head {
      padding-right: 4rem !important;
      .m-cashier-controls {
        column-gap: 2rem !important;
      }
    }

    &:not(.bonus-is-active) {
      .modal-content {
        min-height: initial !important;
        height: 100vh;
      }
    }
  }
}

.m-tab-panel.is-active .piq-cashier-wrapper.is-fullscreen {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto !important;
  z-index: 999;
  overflow-y: scroll !important;
  padding-bottom: 100px;
  background-color: white;
}