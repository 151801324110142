.m-bonus-slider {
  background-image: url('/images/bonus-slider-bg.jpg');
  background-position: center;
  background-size: cover;
  background-color: #84DAF4;
  position: relative;

  @include until($tablet) {
    padding: 0 15px 3px;
  }
  padding: 10px 15px;
  display: none;
  > div > .level {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 10px;
    @include until($tablet) {
      bottom: -10px;
      top: auto;
    }
  }
  &.is-active {
    display: block;
  }
  &.is-active + .modal-card-body .m-tab-panel,
  &.is-active + .modal-card-body .content {
    height: calc(100vh - 390px) !important;
    min-height: 0 !important;
  }

  .delete {
    @include until($tablet) {
      top: 3px;
    }
  }

  &__title {
    color: #004D4D;
    font-size: 2rem;
    font-weight: 700;
    @include until($tablet) {
      font-size: 1.2rem;
    }

    &.de {
      font-size: 1.7rem;

      @include until($tablet) {
        font-size: 1.2rem;
      }
    }
  }

  p {
    color: #004D4D;
    font-weight: 700;
    @include until($tablet) {
      font-size: 0.75rem;
    }
  }

  &__value {
    font-family: 'Gobold Reg';
    font-size: 2.5rem;
    color: $primary;
    @include text-stroke(3,black);
    position: relative;

    @include until($tablet) {
      font-size: 1.5rem;
      @include text-stroke(2,black);
    }
      
  }

  &__bar {
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #004D4D;
    }
    &:after {
      right: 3px;
      left: auto;
    }
  }

  &__label {
    font-family: 'Gobold Reg';
    font-size: 1rem;
    color: $primary !important;
    line-height: 1.1;
    @include text-stroke(2,black);

    @include until($tablet) {
      font-size: 0.75rem;
      @include text-stroke(2,black);
    }

  }

  &__range {
    margin-top: 15px;
  }

  &__element {
    transform: scale(1) rotate(-20deg);

    &.pow {
      @keyframes pow1 {
        0% {transform: rotate(-20deg) scale(1);}
        50% {transform: rotate(-20deg) scale(1.75);}
        100% {transform: rotate(-20deg) scale(1);}
      }
      animation-name: pow1;
      animation-duration: 1s;
    }

    &:last-child {
      transform: rotate(20deg);
      &.pow {
        @keyframes pow2 {
          0% {transform: rotate(20deg) scale(1);}
          50% {transform: rotate(20deg) scale(1.75);}
          100% {transform: rotate(20deg) scale(1);}
        }
        animation-name: pow2;
        animation-duration: 1s;
      }
    }
  }

  &__game-name {
    small {
      @include until(480px) {
        display: inline-block;
        margin-top: -10px;
        font-size: 10px;
      }
    }
  }

  &__container {
    margin: 0;
  }

  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent; /* Otherwise white in Chrome */

  }

  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url('/images/bonus-slider-btn.png');
    background-size: cover;
    border-radius: 3px;
    border: 0px solid;
    box-shadow: 0 0 0;
    outline: 0px;
    position: relative;
    transform: translateY(-45%);
    cursor: pointer;
  }

  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url(/images/bonus-slider-btn.png);
    background-size: cover;
    background-color: transparent; /* Otherwise white in Chrome */
    border-radius: 3px;
    border: 0px solid;
    box-shadow: 0 0 0;
    outline: 0px;
    position: relative;
    z-index: 2;
    //transform: translateY(-45%);
    cursor: pointer;
    
  }

  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url(/images/bonus-slider-btn.png);
    background-size: cover;
    border-radius: 3px;
    border: 0px solid;
    outline: 0px;
    position: relative;
    transform: translateX(-8px);
    cursor: pointer;
   
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #004D4D;
    border-radius: 1.3px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #004D4D;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #004D4D;
    margin-top: 20px;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #004D4D;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }

  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }
}

.m-bonus-slider-simple {
  background-color: #40390D;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 10px 15px;
  display: none;
  color: $grey-panel;

  /*@include until($tablet) {
    padding: 0 15px 3px;
  }*/

  &.is-active {
    display: block;
  }

  .delete {
    @include until($tablet) {
      top: 3px;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 600;
    color: $grey-panel;

    @include until($tablet) {
      //font-size: 1.2rem;
    }
  }

  &__sub-title  {
    color: #b9ccdd !important;
  }

  .promo-code {
    font-size: 1.2rem;

    span {
      display: inline-block;
      padding: 0 .3rem 0 .5rem;
      margin-left: .3rem;
      border-radius: 3px;
      background: $brand-dark-grey;
      color: white;
    }
  }

  p {
    color: $grey-panel;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;

    @include until($tablet) {
      font-size: 0.75rem;
    }
  }

  a {
    color: white;
    font-size: 0.75rem;
  }

  &__value {
    font-family: 'Gobold Reg';
    font-size: 2.5rem;
    color: $primary;
    @include text-stroke(3,black);
    position: relative;

    @include until($tablet) {
      font-size: 1.5rem;
      @include text-stroke(2,black);
    }

  }

  &__bar {
    position: relative;
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #004D4D;
    }
    &:after {
      right: 3px;
      left: auto;
    }
  }

  &__label {
    font-family: 'Gobold Reg';
    font-size: 1rem;
    color: $primary !important;
    line-height: 1.1;
    @include text-stroke(2,black);

    @include until($tablet) {
      font-size: 0.75rem;
      @include text-stroke(2,black);
    }

  }

  &__range {
    margin-top: 15px;
  }

  &__element {
    transform: scale(1) rotate(-20deg);

    &.pow {
      @keyframes pow1 {
        0% {transform: rotate(-20deg) scale(1);}
        50% {transform: rotate(-20deg) scale(1.75);}
        100% {transform: rotate(-20deg) scale(1);}
      }
      animation-name: pow1;
      animation-duration: 1s;
    }

    &:last-child {
      transform: rotate(20deg);
      &.pow {
        @keyframes pow2 {
          0% {transform: rotate(20deg) scale(1);}
          50% {transform: rotate(20deg) scale(1.75);}
          100% {transform: rotate(20deg) scale(1);}
        }
        animation-name: pow2;
        animation-duration: 1s;
      }
    }


  }

  &__container {
    margin: 0;
  }

  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */

  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent; /* Otherwise white in Chrome */

  }

  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url(/images/bonus-slider-btn.png);
    background-size: cover;
    border-radius: 3px;
    border: 0px solid;
    box-shadow: 0 0 0;
    outline: 0px;
    position: relative;
    transform: translateY(-45%);
    cursor: pointer;
  }

  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url(/images/bonus-slider-btn.png);
    background-size: cover;
    background-color: transparent; /* Otherwise white in Chrome */
    border-radius: 3px;
    border: 0px solid;
    box-shadow: 0 0 0;
    outline: 0px;
    position: relative;
    z-index: 2;
    //transform: translateY(-45%);
    cursor: pointer;

  }

  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    height: calc(88px/1.5);
    width: calc(190px/1.5);
    background-image: url(/images/bonus-slider-btn.png);
    background-size: cover;
    border-radius: 3px;
    border: 0px solid;
    outline: 0px;
    position: relative;
    transform: translateX(-8px);
    cursor: pointer;

  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #004D4D;
    border-radius: 1.3px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #004D4D;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #004D4D;
    margin-top: 20px;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #004D4D;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }

  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }
}
