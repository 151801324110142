.m-previously-played {
  background: transparent;
  box-shadow: 0 0 0;
  width: 100%;
  pointer-events: none;

  @include until($desktop) {
    margin-bottom: 50px;
  }

  @include until($tablet) {
    margin-bottom: 45px;
  }

  button.navbar-item {
    background-color: $primary;
    max-width: fit-content;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    pointer-events: auto;
  }

  &.is-active {
    height: 242px;

    i.fa-arrow-circle-up {
      transform: rotate(180deg);
    }
  }

  li,
  .m-game-grid-item {
    margin-bottom: 0;
  }

  .button {
    padding: 0 $gap;
    border-radius: 4px;
  }

  .m-game-grid-buttons {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.m-previously-played-content {
  pointer-events: auto;

  @include until($tablet) {
    width: 100%;
  }

  @include from($tablet) {
    width: 660px;
  }

  @include from($desktop) {
    width: 875px;
  }

  background-color: #000;
  margin: 0;
  border-radius: 0 8px 0 0;
  overflow: hidden;
  padding-bottom: $gap * 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;

  .columns {
    padding-right: 10px;
    overflow: hidden;
    padding-bottom: $gap;
    scroll-behavior: smooth;
  }

  .column {
    min-width: 210px;
  }
}

.m-previously-played-container {
  text-align: left;
  pointer-events: none;
  @include until($tablet) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
