.m-promo-columns {

    &.columns {
        margin-bottom: 0;
        @include from($desktop) {
            margin-bottom: $gap*3;
        }

        > .column {
            @include until($desktop) {
                margin-bottom: 0;
            }
        }
    }

    &-aside {
        margin: $gap/2;
        position: relative;

        @include from($desktop) {
            height: 470px;
        }
    }

    &-column-aside {
        max-width: calc(388px + 15px);
    }

    > a {
        opacity: 1;
        transition: opacity ease 200ms;
        &:hover {
            opacity: 0.8;
            transition: opacity ease 200ms;
        }
    }

    .column.content {
        max-width: 800px;
        width: 100%;
    }

}

.m-promo-layout,
.m-blog-layout {
    &.container.is-fluid {
        padding-top: 50px;
        @include until($desktop) {
            display: flex;
            flex-direction: column;
        }
    }
}
.m-promo-article-layout,
.m-blog-article-layout {

    &.container.is-fluid {
        padding-top: 1.5rem;
        @include until($desktop) {
            display: flex;
            flex-direction: column;
        }
    }
}

.m-blog-list {
    .m-promo-widget-item-text {
        padding: 5px 10px;
        line-height: 1.3;
    }
}


.m-promo-blog-article {
    margin: 5px;
    border-radius: 8px;
    font-size: 1.8em;
    min-width: 600px;
    @include until($tablet) {
        font-size: 1.3em;
        min-width: 0;
    }

    &.content {
        li {
            &::marker {
                color: white;
            }
        }
    }

    > figure {
        @include from($tablet) {
        padding-top: $gap*2;
        margin-left: $gap*2;
        margin-right: $gap*2;
        }
    }
}

.m-promo-blog-article-content {

    @include from($tablet) {
        padding: $gap*4;
        margin-bottom: $gap*4;
    }

    padding: $gap;
    margin-bottom: $gap*2;
}

.m-promo-blog-article-title {
    color: $primary;
    text-align: center;
    margin-bottom: 110px;
    margin-top: 50px;
    @include until($tablet) {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .title {
        position: relative;
        color: $primary;
        margin: 0 0 14px;

        &:after {
            content:'';
            display: block;
            width: 490px;
            height: 3px;
            background-color: white;
            margin: auto;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
                @include until($tablet) {
                    width: 100%;
                    max-width: 280px;
                }
            }
        }
}

.m-promo-blog-article-subtitle {
    color: white !important;
    text-align: center;
}

.m-promo-article-background {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
    padding: 0 0 100px;
    text-shadow: 0 3px 5px rgba(0,0,0,0.5);

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        width: 100%;
    }

    &:after {
        content: '';
        display: block;
        background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
    }
}

.m-promo-small-print {
    margin-top: 40px;
  
    
    > div {
        margin-top: 40px;
    }

    .title {
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: -10px;
            height: 3px;
            width: 390px;
            background-color: white;
            left: 50%;
            transform: translateX(-50%);
            @include until($tablet) {
                width: 100%;
                max-width: 280px;
            }
        }
    }
}

.m-promo-button {
    margin: 100px 0;
    @include until($tablet) {
        margin: 30px 0;
    }
}

.m-promo-spins {
    max-width: 620px !important;
    margin-left: auto;
    margin-right: auto;
}

.m-promo-block-content {
    font-size: 134%;
    max-width: 60%;
    margin: auto;
    line-height: 1.25;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    @include until(680px) {
        font-size: 100%;
        max-width: 100%;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    p {
        text-align: center;
    }
    + .m-game-grid-button {
        @include until(680px) {
            white-space: normal;
            height: auto;
            min-height: 50px;
        }
    }
}

.m-promo-block-foreground-image {
    @include from(680px) {
        max-width: 600px !important;
    }
}

@include until(2452px) {
    .m-promo-block-carousel {
        max-height: none !important;
        .carousel-cell {
            max-height: none !important;
        }
    }
}

.m-promo-block-carousel {
    min-height: 0;
    padding: 40px 0 0;
    margin-bottom: 70px;
    @include until($desktop) {
        padding: 20px 0 0;
        margin-bottom: 30px;
    }
    .hero-title-wrapper {
        margin-bottom: 0 !important;
        line-height: 0 !important;

        img {
            @include until(680px) {
                max-height: 40px !important;
            }
        }
    }
    .carousel-cell {
        height: auto !important;
        min-height: 500px;
        padding: 25px 0;
        display: flex !important;
        @include until(680px) {
            height: 240px !important;
            min-height: 0 !important;
        }
        &:before,
        &:after {
            display: none;
        }
    }
    .carousel-bg-image {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-size: cover;
        background-position: center;
        img {
            bottom: 0;
            top: 0;
            position: absolute;
            height: 100%;
         
            @include from(1920px) {
                display: none;
            }
        }
        video {
            height: 500px;
            width: 1920px;
            object-fit: cover;

            @include until(690px) {
                width: auto;
                height: 240px;
            }

            @include from(1920px) {
                width: 100%;
                height: auto;
            }
        }

        
    }
    .container {
        position: static !important;

        &.m-button-only {
            margin-bottom: 50px;

            @include until(680px) {
                margin-bottom: 0;
            }
        }
    }
}

.m-promo-carousel {
    display: block;
    &.is-disabled {
        pointer-events: none;
    }
}

.m-featured-games-holder {
    #game-grid {
        @include from(840px) {
            width: calc(100vw - 40px);
            transform: translateX(-50%);
            margin-left: 50%; 
        }
    }
}