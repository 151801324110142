.m-support-bg {
  //background-image: url('/images/promotions/bg-welcome-offer.jpeg');
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;

  .container {
    max-width: none !important;
  }
}

.m-support-item {
  margin-bottom: $gap * 4;
  display: flex;
  padding: $gap*2;
  background-color: $black;
  border-radius: 8px;
  border:1px solid darken($primary, 20%);

  .media {
    align-items: flex-start;

    @include until($desktop) {
      align-items: center;
    }
  }
  .media-left {
    margin-right: 30px;
  }
  @include until($tablet) {
    padding: 5px 5px 10px;
    .media {
      display: block;
      position: relative;
    }
    .media-left {
      margin-right: 0;
    }
    .media-content {
      padding-top: 20px;
      padding: 20px;
    }
    .button {
      display: flex;
    }

    .content {
      display: flex;
      flex-direction: column;

      .button {
        justify-content: center;
      }
    }
  }

  &-image {
    width: 300px;
    border:1px solid $primary;

    &-holder {
      //background-image: url('assets/static/support/bg.jpg');
      background-position: center;
      background-size: cover;
      width: 300px;
      height: 210px;
      display: flex;

      img {
        margin: auto;
        max-height: 210px;
      }

      @include until($tablet) {
        width: 100%;
        img {
          margin: auto 0 auto auto;
        }
      }
    }

    > * {
      padding: 0px !important;
    }

    @include until($widescreen) {
      background-position: top right;
    }
  }

  &-title {
    font-weight: 800 !important;
    @include text-stroke(2, $dark);
    @include until($tablet) {
      position: absolute;
      top: 90px;
      left: 10px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-text {
    padding: $gap;
    margin: $gap 0;
    display: flex;
    flex-direction: column;
  }

  &-button {
    margin-top: auto;
  }

  @include until($fullhd) {
    .m-title-font {
      @include text-stroke(2, $primary);
    }
  }
}

.m-social {
  margin-top: $gap * 3;
  position: relative;
  line-height: 100px;

  .column {
    text-align: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      height: 3px;
      left: 10%;
      right: 10%;
      background-color: $primary;
      position: absolute;
      top: calc(112px / 2);
      left: 0;
      right: 0;
      z-index: 0;

      @include until($tablet) {
        top: calc(64px / 2);
      }
    }

    @include until($tablet) {
      .social-image {
        max-height: calc(112px / 2);
      }
    }
  }

  i {
    font-size: 7rem;
    background: $white;

    @include until($desktop) {
      font-size: 4rem;
    }

    &.icon-facebook {
      color: $facebook;
      transition: color ease 100ms;

      &:hover {
        color: transparentize($facebook, 0.25);
        transition: color ease 100ms;
      }
    }

    &.icon-twitter {
      color: $twitter;
      transition: color ease 100ms;

      &:hover {
        color: transparentize($twitter, 0.25);
        transition: color ease 100ms;
      }
    }
    &.icon-youtube {
      color: $youtube;

      &:hover {
        color: transparentize($youtube, 0.25);
        transition: color ease 100ms;
      }
    }
  }
}

.social-image {
  position: relative;
}

.m-bucket-page-content {
  margin: auto;
}

.m-bucket-page-items {
  margin: auto;
}
