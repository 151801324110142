.pageloader::after {
  z-index: 1;
  display: none;
}

.pageloader {
  background-color: $brand-dark-grey;
  .title {
    letter-spacing: 0;
    line-height: 4;
  }
  img {
    position: absolute;
    transform: translateX(-50%) translateY(-100%);
    z-index: 99999;
    max-width: 100px;
  }
}

.loader__holder {
  position: relative;

  .loader {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.loader {
  border-left-color: $primary;
  border-bottom-color: $primary;
}