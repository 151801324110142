.m-payment-carousel {
  position: relative;
  height:200px;
  max-width: 1921px;
  margin: 0 auto;
  overflow: hidden;
  
  @include until($tablet) {
    position: relative;
    left: calc(100% + 240px - 40px);
    display: none;
  }

  &__curve {
    background: url('/images/curves-02.png') no-repeat;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    height: 192px;
    position: relative;
    z-index: 2;
    position: relative;
    z-index: 2;
    top: 62px;
  }
}

.m-payment-carousel__logos {
  position:absolute;
  width:calc(100vw + 600px);
  height:200px;
  left:-300px;
  @include from($tablet) {
    left: auto;
    right: calc(-100vw + 290px);
  }
  opacity: 0;
  margin-top: 100px;
}

.m-payment-carousel__logo {
  position:absolute;
  width: 240px;
  height:160px;
  padding:25px;
  font-size:40px;
  font-weight:600;
  line-height:60px;
  border: 1px solid $primary;
  border-radius: 8px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  font-size: 4rem;
  color: white;

  &--hidden {
    display: none;
    width: 0px;
  }

  .icon-logo-ezeewallet,
  .icon-logo-ecopayz,
  .icon-logo-skrill,
  .icon-logo-mifinity,
  .icon-logo-revolut,
  .icon-logo-cashtocode,
  .icon-logo-coinspaid {
    font-size: 50% !important;
  }

  .icon-logo-18 {
    font-size: 2.5rem;
  }
  
  img {
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 160px;
  }

  .icon-logo-FF-Gaming-Group {
    font-size: 48px;
  }
}