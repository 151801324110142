.m-tournament {
  max-width: 2400px;
  width: 100%;

  .m-tournament-layout {
    display: flex;
    flex-direction: column;
    margin: 100px auto;

    @include until($desktop) {
      margin: 2rem 0;
    }
  }

  .m-tournament-hero {
    position: relative;

    .m-tournaments-hero-title {
      position: absolute;
      top: 3rem;
      color: $brand-dark;

      @media screen and (max-width: $desktop) {
        display: block;
        width: 100%;
        text-align: center;
        top: 0;
      }

      @media screen and (min-width: $desktop) {
        margin-left: -10px;
        padding: 10px 50px;
        display: inline-block;
      }
    }

    @include until($tablet) {
      order: 2;
    }
  }

  .m-tournament-nav {
    .m-tournament-nav-button {
      line-height: 3rem;
      border: 0px solid;
      padding: 0 0 0 15px;
      border-radius: 5px;
      margin: 10px;
      background-color: $brand-yellow;
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .navbar-item {
      color: $primary;
      border: 0px solid;
      font-size: inherit;
      font-weight: inherit;
      outline: 0;
      cursor: pointer;

      @include until($desktop) {
        width: 100%;
      }
    }

    @include until($desktop) {
      order: 1;
      padding: 2rem 0;

      .m-tournament-nav-menu {
        display: none;
      }

      .m-tournament-nav-menu.is-active {
        transform: translateX(-50%);
        left: 50%;
        top: 3.9rem;
        background-color: $brand-dark;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        text-align: center;

        a {
          display: block;
          color: $white;
          &.is-active,
          &:hover {
            background-color: $white;
            color: $brand-grey;
          }
        }
      }
      .level-left {
        margin: auto;
      }
    }

    @include from($desktop) {
      font-weight: bold;
      margin-bottom: $gap * 4;

      .navbar-item {
        color: $primary;
        &.is-active {
          background: transparent !important;
          color: $primary;
          text-decoration: underline;
        }
        &:hover,
        &:focus {
          color: $primary;
          text-decoration: underline;
          background: transparent !important;
        }
      }
    }
  }

  .m-tournament-main {
    padding: 0 1rem;

    .m-tournament-sub-header {
      position: relative;

      .m-tournaments-main-subtitle {
        position: absolute;
        top: 15%;
        left: 0;
        padding: 0 15px;
        display: inline-block;
        z-index: 1;
        color: $brand-dark;

        @media screen and (max-width: $desktop) {
          left: 1rem;
          text-align: center;
        }

        @media screen and (min-width: $desktop) {
          margin-left: -10px;
          display: inline-block;
          width: initial;
        }
      }
    }

    .content {
      margin-bottom: 3rem;
    }

    .m-tournament-table {
      background-color: #000;
      color: $brand-dark;

      .m-tournament-table-header {
        border: 3px solid $brand-yellow;

        .m-tournament-header-title {
          padding: 0.5rem 1rem;
          margin: 0;

          & > div {
            margin: 0;
            padding: 0.3rem 0;
            text-align: center;
          }

          .level-right {
            @include until($desktop) {
              width: 100%;
            }
          }

          .title,
          .subtitle {
            margin: 0;
            align-items: center;
            color: $brand-yellow;
            width: 100%;
          }

          .subtitle {}

          i {
            margin-left: 0.5rem;
            color: $brand-yellow;
          }

          &.is-open {
            i {
              transform: rotate(180deg);
            }
          }

          @include until($desktop) {
            flex-direction: column;
            justify-content: center;
          }
        }

        .m-tournament-header-dates {
          background-color: $brand-yellow;
          padding: 1rem;

          & > div {
            .table-container {
              width: 100%;
            }

            table tr {
              color: $brand-dark;
              justify-content: space-between;

              td {
                padding: 0.2rem 0.5rem;
                width: 100%;

                &.m-end-date {
                  background-color: #000 !important;
                  color: #fff !important;
                  border-radius: 10px;
                  padding: 0.1rem 0.5rem;
                  align-items: center;
                  text-align: right;
                }

                &:first-child {
                  text-align: left;
                }
              }
            }
          }

          .level-left {
            .table {
              width: 100%;

              tr {
                justify-content: space-between;
                td {
                  .m-end-date {
                    background-color: #000;
                    border-radius: 10px;
                    color: #fff;
                    padding: 0.2rem 0.6rem;
                    display: block;

                    @include from($tablet) {
                      width: 70%;
                    }
                  }
                }
              }
            }

            @include from(1200px) {
              width: 50%;
            }
          }

          .level-right {
            margin-top: 0;


            div {
              align-items: flex-end;
            }

            @include until($desktop) {
              padding-top: 0.5rem;

              p {
                text-align: center;
              }
            }

            @include from(1200px) {
              width: 50%;
            }
          }

          @include until($tablet) {
            padding: 0.5rem;
          }

          @include until(1200px) {
            display: block !important;

          }
        }
      }

      .m-tournament-table-body {
        cursor: pointer;
        border: none;
        outline: none;
        transition: 0.4s;

        .columns {
          flex-direction: column;

          .column {
            margin: 0 2rem;

            @include until($tablet) {
              margin: auto 0;

              &:first-child {
                margin-bottom: 1rem;
              }
            }

            &:nth-of-type(2) {
              table.current-player {
                background: #4A4A4A;
                color: $brand-yellow;
                font-weight: bold;
                margin: 1rem 0 .8rem;

                td:last-child {
                  text-align: right;
                }
              }

              & > .button {
                margin: 1.5rem auto .5rem auto;
                display: block;
              }
            }
          }
          @include until($tablet) {
            padding: 0 1rem;
          }

          @include from(1200px) {
            flex-direction: row;
          }
        }

        .m-ranking-table-title {
          justify-content: space-between;

          h3 {
            color: #fff;
            width: 100%;
          }

          @include until($tablet) {
            padding: 0 1rem;
          }
        }

        .m-ranking-table-body {
          max-height: 275px;
          height: 100%;
          overflow-y: auto;
          margin-bottom:1rem;

          .m-ranking-table-wrapper {
            overflow-y: auto;

            .table {
              width: 100%;
              font-size: 0.8rem;

              tbody {
                tr {
                  &:nth-child(odd) {
                    background-color: #4a4a4a;
                  }
                  .m-user-score,
                  .m-user-win-price {
                    text-align: right !important;
                  }

                  &.is-active {
                    color: $brand-yellow;
                    font-weight: bold;
                  }
                }
              }

              @include from($tablet) {
                font-size: 1rem;
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }

            &::-webkit-scrollbar-track {
              background: #000;
            }

            &::-webkit-scrollbar-thumb {
              background: $brand-yellow;
              border-radius: 5px;
              height: 50%;
            }

            &::-webkit-scrollbar-corner {
              background: rgba(0,0,0,0.5);
            }

            &::-webkit-scrollbar-thumb:hover {
              background: darken($brand-yellow, 10%);
            }
          }

          /* width */
          &::-webkit-scrollbar {
            width: 7px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: $brand-dark;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $brand-yellow;
            border-radius: 5px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: darken($brand-yellow, 5%);
          }
        }

        .m-game-section-title {
          h3 {
            color: #fff;
            width: 100%;
          }
        }

        .m-game-section {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;

          &.one-game-only {
            .m-game-section-container {
              width: 300px;
            }

            .m-game-navigation-left,
            .m-game-navigation-right {
              display: none;
            }
          }

          .m-game-section-container {
            overflow: hidden;
            width: 100%;

            .slider {
              &.animated {
                min-height: 195px;
              }

              .slide {
                img {
                  height: 200px;
                }
              }
            }

            @include from(1200px) {
              width: 300px;
            }

            @include from(1610px) {
              width: 600px;
            }
          }

          .m-game-navigation-left,
          .m-game-navigation-right {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 90px;

            @include until($tablet) {
              width: 70px;
            }
          }

          .m-game-navigation-left {
            left: 0rem;

            @include until($tablet) {
              left: -1.5rem;
            }

            @include from($tablet) {
              left: -2rem;
            }
          }

          .m-game-navigation-right {
            right: 0rem;

            @include until($tablet) {
              right: -1.5rem;
            }

            @include from($tablet) {
              right: -2rem;
            }
          }

          @include until($desktop) {
            width: 95%;
          }

          @include from($desktop) {
            width: 98%;
          }
        }

        @include until(1200px) {
          padding-bottom: 2rem;
        }
      }

      @include until($tablet) {
        padding: 0;
        .m-tournament-table-header {
          text-align: center;
          .m-tournament-header-title {

            .level-right {
              justify-content: center;
            }
          }

          .m-tournament-header-dates {
            display: block;
          }
        }

        .title,
        i {
          margin: 0;
        }
        .title {
          margin-right: 0.5rem;
        }
      }

      &.is-closed {
        .m-tournament-table-body {
          padding: 0 18px;
          background-color: $brand-dark;
          display: none;
          overflow: hidden;
        }

        a {
          color: #fff;
        }

        .m-tournament-table-header {
          color: #fff;

          .m-tournament-header-title {
            i {
              transform: rotate(180deg);
            }
          }
        }

        .m-tournament-header-dates {
          background: #000;
          color: #fff;

          .level-left {
            table {
              tr {
                color: #fff;
              }
            }
          }
        }
      }
    }

    @include until($desktop) {
      order: 3;
      .content {
        margin: 2rem 0;
        padding: 0;
      }
    }

    .m-tournament-loader {
      margin-bottom: 1rem;

      &.wrapper {
        background: #1d2326;
        width: 100%;
        padding: 1rem;

        .item {
          background: transparent;
          border-radius: 3px;
          padding: 12px 0;
          margin: 0 auto;
          width: 100%;

          &.header {
            padding-bottom: 0;
            > div:first-child {
              height: 60px;
            }

            &__body {
              height: 130px;
              animation-delay: 1s;
            }
          }

          &.body {
            padding: 0;

            &__title {
              height: 70px;
            }
            &__wrapper {
              display: flex;
              justify-content: space-between;
              height: 250px;

              .body__left,
              .body__right {
                height: 100%;
                width: 48%;
              }
            }
          }
        }

        @keyframes placeHolderShimmer{
          0%{
            background-position: -850px 0
          }
          100%{
            background-position: 850px 0
          }
        }

        .animated-background {
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background: #f6f7f8;
          background: linear-gradient(to right, #191F20 8%, #2b2b2b 18%, #191F20 33%);
          background-size: 800px 104px;
          height: 100%;
          position: relative;
          border-radius: 5px;
        }
      }
    }
  }

  .m-tournament-sidebar {
    .columns {
      flex-direction: column;
      gap: 1rem;
      margin-left: 1rem;

      .column {
        border: 1px solid $brand-grey;
        width: 100%;
      }
    }
    @include until($tablet) {
      display: none;
    }
  }
}
