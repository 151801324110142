.game-screen__iframe {
  overflow: hidden;
}

.game-screen__iframe-container {
  position: relative;
  transform: scale(0.8);

  &.is-active {
    background-color: $grey-panel;
    box-shadow: 0 0 20px;
    transition: background-color 0 ease 5000, box-shadow 0 ease 5000;
  }
}

.m-game-login-area {
  padding: $gap;

  &.m-game-login-area-logged-in {
    .level-left {
      flex: 0;
    }

    .level-right {
      justify-content: space-between;
    }
  }

  .level-left {
    flex: 0 1 auto;

    @include from($fullhd) {
      padding-left: 178px;
      justify-content: center;
    }
  }

  .level-right {
    @include until($desktop) {
      flex: 0 1 auto;
      justify-content: flex-end;
      flex-grow: 1;
    }
  }

  .box {
    @include until($desktop) {
      margin: 0 5px;
    }
    margin: 0 $gap 0 0;
    padding: $gap/2 $gap;
  }

  .m-deposit-button {
    padding: $gap/2 $gap * 2;
    border: 0px solid;
    height: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    //background-color: $success;
    color: white;
    margin-right: $gap/2;
    @include from($desktop) {
      margin-left: $gap * 2;
      margin-right: $gap * 2;
    }

    &:hover {
      background-color: darken($success, 10%);
    }
  }
}

.m-game-tools {
  .button {
    background-color: transparent;
    color: $dark;
    font-size: 22px;
    height: auto;

    &:last-child {
      margin-left: $gap/2;
    }

    @include from($desktop) {
      margin-left: $gap;
      margin-right: $gap;
    }
  }
  @include from($desktop) {
    padding-right: $gap;
    padding-left: $gap;
  }
}

.game-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  background-size: cover;

  @include until($desktop) {
    height: calc(100vh);
    margin-top: 120px !important;
  }
  @include until($tablet) {
    height: calc(100vh + 105px);
  }

  @media screen and (min-width: 320px) and (max-width: 812px) and (orientation: landscape) {
    padding-top: 135px;
    height: calc(100vh);

    .game-screen__iframe-container {
      transform: scale(1.25) !important;
    }
  }
}

.m-game-page-notification {
  position: fixed;
  z-index: 9999;
  width: 100%;
  @include until($desktop) {
    top: 90px;
  }

  .container {
    margin: auto;
    max-width: 1630px;
  }

  .notification {
    padding-top: $gap;
    padding-bottom: $gap;

    @include until($tablet) {
      padding-left: $gap;
      padding-right: $gap;
    }
    .media {
      align-items: center;
    }
  }
}

#game {
  .m-previously-played-container,
  .m-tool-bar,
  .m-support-tab-container {
    @include until($widescreen) {
      display: none;
    }
  }
}
