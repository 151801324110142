// =========================================================================
// Layout - Sections of Page
// =========================================================================

// =========================================================================
// Site Header
// =========================================================================

.header.is-fluid.container:not(.rtp) {
    max-width: $container-max-width !important;
    margin: auto;
    padding: 15px $gap;


    @include until($desktop) {
        box-shadow: 0 0 30px rgba(0,0,0,0.4);
        position: relative;
        top: 0;
        z-index: 32;
        width: 100%;
        background-color: black;
        padding: 0;
    }

    @include until($tablet) {
        padding-right: 0;
    }
}

.m-site-header {
    min-height: 100px;
    margin: auto;
    box-shadow: 0 0 10px rgb(0,0,0);
    position: relative;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: black;
    width: 100%;

    @include until($desktop) {
        position: fixed;
    }

    .header {
        background-color: black;
        min-height: 100px;
        padding: 0 20px;
        max-width: $container-max-width;
        margin: auto;
        
        @include until($tablet) {
            padding: 0 5px;
        }

        .level {
            min-height: 100px;
            padding: 10px;

            &-right {
                #modal-signup-button {
                    margin-top: 0;
                }
            }
        }
    }

    @include until(375px) {
        min-height: 100px;
        .header {
            min-height: 100px;
            padding: 0 10px;
            .level {
                min-height: 100px;
            }
        }
    }

    .container {
        max-width: 1920px;
    }

    &.m-game-header {
        
        box-shadow: 0 0 0;
        background-color: black;

        @include until ($desktop) {
            position: fixed;
        }
        
        .header {
            min-height: 50px;
            padding: 0 20px;
            position: relative;
            background-color: transparent;
            .level {
                min-height: 50px;
            }

            .m-top-nav-brand {
                position: absolute;
                left:50%;
                transform: translateX(-50%);
            }
        }
        img {
            width: 130px;
        }

        &.m-game-header--real-mode + .main-game {

            .game-screen {
                height: calc(100vh - 65px);
            }
        }
        @include until($desktop) {
            .navbar-burger {
                color: $brand-dark;
            }
            .navbar-burger:hover {
                color: $brand-dark;
                background-color: unset;
            }
          }
    }
}

.m-header-account-sign-in {
    .field {
        margin-top: 0 !important;
    }
    .help {
        position: absolute;
        margin-top: 0;
    }

    .button.is-dark-green {
        @extend .is-dark-green;
    }

    #modal-signup-button {
        @include until($desktop) {
            padding: 1rem;
        }
    }
    #logout {
        //margin-right: $gap;
    }
}

.m-top-nav-brand.rtp {
    justify-content: flex-start;
}

.m-top-nav-brand img {
    margin-top: 10px;

    @include until($tablet) {
        margin-top: 5px;
    }
}

.m-header-balance {
    background-color: transparent;
    margin: auto;
    border: 0px solid;
    display: block;
    color: $success;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
}