.circle-element-container {
    background: $brand-grey;
    border-bottom: 2px solid lighten($brand-dark, 0.8);
    border-radius: 50%;
    width: 110px;
    height: 110px;
    font-size: 48px;
    text-align: center;
    color: white;
    display: block;
    margin: $gap auto $gap;

    @include until($tablet) {
        margin: 10px auto 10px;
    }

    .icon {
        vertical-align: top;
        width: 100%;
        height: 110px;

    }

    &:hover {
        background-color: $primary;
        color: $brand-dark;
    }
}