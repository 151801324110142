.m-carousel .carousel-cell {
.m-promotion-hero {
    img {
      max-width: 100%;
      height: auto;
      width: auto;
      margin: auto;
      position: static;
      transform: translate(0);

      @include until(575px) {
        max-height: 150px;
      }
    }
  }

  @include until(575px) {
    .hero-body {
      padding: 10px;
    }
  }
  .hero-title-wrapper {
    line-height: 74px;
   
    > .columns {
      @include until(575px) {
        transform: scale(0.5);
      }
    }
  }

  .m-hero-bonus-amount {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    font-size: 4rem;
    font-weight: 900;
    padding-top: 22px;
  }
  
  .m-hero-bonus-text {
    text-shadow: 0 0 10px rgba(0,0,0,0.75);
    font-size: 88px;
    font-weight: 900;
  }
  
  .m-hero-bonus-currency {
    font-size: 60px;
    padding-top: 20px;
  }
}

.m-promotion-hero {
  .hero-body {
    padding: 0 !important;
  }
}