.m-landing-page-features {
  max-height: 980px;
  background-image: url('/images/bg-landing-page.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  width: 100%;
  max-width: 1920px;
  aspect-ratio: 1920/980;
  padding-top: 120px;
  margin: auto;
  position: relative;
  z-index: 2;
  @include until($tablet) {
    padding-top: 10px;
    background-size: cover;
    aspect-ratio: 1360/980;
  }

  p {
    max-width: 470px;
    width: 100%;
    margin: auto;
    margin-bottom: 2em;
    text-transform: uppercase;
    line-height: 1.25;
    text-align: center;
  }

  &__title {
    font-size: clamp(0.5em, 5vw, 3em) !important;
  }

  &-content {
    max-width: 1100px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin: 0 auto;

    @include from($widescreen) {
      display: block;
    }

    @include until($tablet) {
      font-size: 1.2rem;
    }

    ul {
      text-shadow: 0 0 10px rgba(0,0,0,0.5);
    }
  }

  &-coins {
    position: absolute;
    top: -50px;
    margin: auto;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    pointer-events: none;
    img {
      max-width: 1780px;
      width: 100%;
    }

    @include until($desktop) {
      top: 70px;
      transform: translate(0px, 0px);
    }
  }

  .button {
    font-size: clamp(1em, 2vw, 2em);
    height: 2em;
    color: #000;
    margin-bottom: 2em;
    animation: pulse-button 4s infinite;
  }
}

.m-landing-page-section {
  margin-top: 50px;
  background-image: url('/images/curves.png');
  background-position: bottom center;
  padding-bottom: 200px;

  @include until($tablet) {
    padding-bottom: 120px;
  }

  position: relative;
  z-index: 1;

  &__title {
    font-size: clamp(2em, 5vw, 4.5em) !important;
  }

  p {
    max-width: 460px;
    width: 100%;
    margin: auto;
    text-transform: uppercase;
    line-height: 1.25;
  }

  .button {
    font-size: clamp(1em, 2vw, 2em);
    height: 2em;
    margin-bottom: 2em;
    animation: pulse-button-black 4s infinite;
  }

  &-loggedIn {
    padding: 0;
  }
}

.m-landing-page-spinnalot-logo {
  margin-right: 0.5rem;

  @include until($tablet) {
    width: 130px;
  }
}

@keyframes pulse-button {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 221, 25, 0.7);
  }
  
  40% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 221, 25, 0);
  }
  
  80% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 221, 25, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 221, 25, 0);
  }
}

@keyframes pulse-button-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  
  40% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.7);
  }
  80% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(255,255,255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}