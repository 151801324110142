.select,
.select select {
  width: 100%;

  &:after {
    border-color: #363636 !important;
  }
}

fieldset {
  border: 0px solid;
}

.m-legend {
  padding-bottom: $gap * 2;
  margin-bottom: $gap * 2;
  border-bottom: 1px solid $primary;
  display: block;
  width: 100%;
  color: $grey-light;
}

.m-fieldset {
  margin-bottom: $gap * 2;
}

.is-checkradio[type='checkbox'] + label::before,
.is-checkradio[type='checkbox'] + label:before,
.is-checkradio[type='checkbox']:checked + label::after,
.is-checkradio[type='checkbox']:checked + label:after {
  margin-top: 5px;
}

.is-checkradio[type='checkbox']:checked + label::after,
.is-checkradio[type='checkbox']:checked + label:after {
  border-width: 3px;
  width: 0.4rem;
  height: 0.8rem;
  top: 0.3em;
}

.m-field-checkbox-group {
  .m-field-checkbox {
    margin-left: 18px !important;
  }
}

.m-field-label + .field-body {
  padding-right: 20px;
}

.header,
.m-game-login-area {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: $brand-dark !important;
    -webkit-box-shadow: 0 0 0 30px $brand-dark inset !important;
    -webkit-text-fill-color: $primary !important;
    font-size: 0.9rem;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primary;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: $primary;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    color: $primary;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: $primary;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: $brand-grey !important;
  -webkit-box-shadow: 0 0 0 30px $brand-grey inset !important;
  -webkit-text-fill-color: $grey-light !important;
}

.input,
.select,
.select select {
  background-color: $brand-grey;
  color: $grey-light;
  &:after {
    border-color: $grey-light !important;
  }
}

.textarea {
  color: $grey-light;
}

.control.has-icons-left .input:focus ~ .icon {
  color: $grey-light;
}

.label {
  color: $grey-light;
}

.field-check-box {
  a {
    color: $grey-light;
    text-decoration: underline;
  }
}

.control.has-icons-left .input[disabled] + .icon {
  color: $brand-dark;
}

.m-alert-message {
  max-width: 100%;
}

.is-verification-input {
  letter-spacing: 10px;
}

.m-input-conditional {
  border: 1px solid white;
  border-radius: 50px;
  background-color: black;
  height: 50px;
  overflow: hidden;
  display: flex;
  .radio {
    width: 50%;
    input:checked + .m-input-conditional-item {
     background-color: $primary;
      + {
        .m-input-conditional-name {
          color: black;
        }
      }
    }
    &:hover {
      color:white;
      text-decoration: underline;
    }
  }
  &-item {
    height: 50px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-color: $dark;
  }
  &-name {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 22px;
    text-align: center;
    line-height: 2.3;
    text-transform: uppercase;
  }
  &-or {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    color: black;
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%) translateY(-5px);
    text-align: center;
    font-size: 27px;
    text-align: center;
    line-height: 2.25;
    text-transform: uppercase;
  }
}

.m-consent-switch {
  span {
    margin-top: -22px;
  }
  i {
    position: absolute;
    left: 9px;
    top: 5px;
    opacity: 0;
  }
  
  input:checked + label {
    i {
      opacity:0.25;
    }
  }
  .switch[type="checkbox"]:checked.is-large.is-rounded + label:after {
    background-color: $success !important;
  }
  .switch[type="checkbox"].is-large.is-rounded + label:before {
    border-color: $grey !important;

  }
  .switch[type="checkbox"]:checked.is-large.is-rounded + label:before {
    border-color: $light !important;
  }
  label:before {
    border: 1px solid !important;
    background-color: $dark !important;
  }
  label:after {
    background-color: $grey !important;
  }
}
.custom-password-field.multiple-icons {
  .icon:first-of-type {
    display: none;
  }
}