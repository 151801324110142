.m-grid-item {
    position: relative;
    &-caption {
        position: absolute;
        left:0;
        right: 0;
        padding: $gap;
        bottom: 0;
        background-color: rgba(0,0,0,0.3);
        color: white;
        line-height: 1.3;
    }

    .m-grid-item-calendar-day {
        position: absolute;
    }

    &-icon {
        position: absolute;
        top: -2px;
        right: -2px;
        font-size: 4rem;
        color: white;
        line-height: 0;
        border-radius: 50%;
        text-align: left;
        overflow: hidden;
        letter-spacing: 1px;

        &:before {
            content: '';
            background-color: black;
            display: block;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 2px;
            border-radius: 50%;
            right: 2px;
        }

        i {
            position: relative;
        }
    }
}

.m-grid-item-calendar-day {
    background-color: $dark;
    color: white;
    font-size: 50px;
    padding: 0 10px;
    font-weight: bold;
    font-family: 'Helvetica Neue', 'Arial', sans-serif;
    line-height: 1.3;
    letter-spacing: -1px;
}

.m-grid-row-seperator {
    padding: 0 $gap/2 $gap*3;

    @include until($desktop) {
        padding: $gap*2 $gap/2 $gap*3;

        .m-promo-section & {
            order: 3 !important;
        }
    }
}