// =========================================================================
// Layout - Sections of Page
// =========================================================================

// =========================================================================
// Footer
// =========================================================================

.footer {
    position: relative;
    z-index: 4;
    background-color: transparent;
    border-top: 27px solid $primary;
    padding: 3rem 1.5rem;
    color: $footer-color;

    @include until($desktop) {
        margin-top: 0;
    }

    @include until($tablet) {
        padding-bottom: 140px;
    }

    &:before {
        content:'';
        display: block;
        position: absolute;
        height: 10px;
        background-color: #714103;
        top: 2px;
        width: 100%;
        left: 0;
    }
    &:after {
        content:'';
        display: block;
        position: absolute;
        height: 10px;
        background-color: #005529;
        top: 14px;
        width: 100%;
        left: 0;
    }

    .container {
        max-width: 1915px !important;
        margin: auto;
        padding: 0;
        width: 100%;
    }

    a {
        color: $footer-color;

        &:hover {
            text-decoration:underline;
            color: white;
        }
    }

    i {
        font-size: 300%;

        @include until(350px) {
            font-size: 200%;
        }
    }
    .columns {
        justify-content: center;
        .column {

        }
    }

    hr {
        height: 1px;
        background-color: $white;
    }

    .separator {
        position: relative;
        overflow: hidden;
        p {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -52%);

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -854px;
                height: 1px;
                background-color: white;
                right: 100%;
                top: 22px;
            }
            
            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 100%;
                height: 1px;
                background-color: white;
                right: -854px;
                top: 22px;
            }
            
            @include until($desktop) {
                width: 70%;
            }

            @include until($tablet) {
                font-size: 2vw !important;
                width: 80%;
            }
        }
    }
}


.game-footer {
    padding: 1rem 1rem;

     @include until($tablet) {
        display: none;
    }
}

.footer-logos {
        font-size: 120%;
        margin: 2rem 0;
        @include until(1730px) {
            justify-content:center;
            line-height: 55px;
            font-size: 1vw;
        }

        &:first-of-type {
            @include from(1595px) {
                row-gap: 0.5rem;
            }
        }

        &-providers {
    
            @include from(1268px) {
                padding-bottom: 20px;
            }
            @include until(1268px) {
                font-size: 1.8vw;
            }
            @include until(600px) {
                font-size: 3vw;
            }
            .column {
                flex-grow: 0;
                display: flex;
                justify-content: center;
                margin: 10px 0;
                
                @include until(1730px) {
                    padding: 10px;
                    flex-grow: 1;
                }
                @include until(400px) {
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    hr {
        height: 1px;
        background-color: $grey;
    }

    h4, .h4, h5, .h5, h6, .h6 {
	    margin-bottom: 0;
    }
}

.m-footer-copyright {
    font-size: .7rem;
    line-height: initial;
    text-align: left;
    padding: 0 1rem;

    a {
        display: inline-block !important;
        text-decoration: underline !important;

        &:hover {
            text-decoration: underline !important;
        }
    }
    
    @include until($desktop) {
        margin: 1rem 0;
    }
    @include until($tablet) {
        margin-top: 0;
        padding: 0;
    }
}

.footer-logos-payment-providers {

    max-width: 1840px;
    justify-content: space-between;
    
    @include until(1300px) {
        max-width: 100%;
    }

    @include until(900px) {
        justify-content: center;
    }

    .column {
        margin: 0;
        margin: 0;
        min-height: 70px;
        aspect-ratio: 115/100;
        padding: 8px 10px;
        max-height: 80px;
    }

    .image {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        margin: 5px;
        min-height: 0;
        opacity: 1;
        width: 100%;
        img {
            max-height: 40px;
            width: auto;
            height: auto;
            margin: auto;
            
            @include from(620px) {
                max-width: 100%;
            }
            @include until($fullhd) {
              
            }
        }
    }
}

.footer-logos-payments {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75rem;
    row-gap: 0.5rem;
    
    &:nth-last-of-type(2) {
        margin-top: 0;
    }

    .m-brand {
        justify-content: space-around !important;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0;
        row-gap: 0;

        @include from($desktop) {
            padding: 0 1rem;
        }

        & > .column {
            @include until($widescreen) {
                height: 35px;
                width: 33.33333%;
            }
            @include until($tablet) {
                width: 33.3333%;
                height: 50px;
            }

            .icon-logo-18 {
                @include until($tablet) {
                    font-size: 180% !important;
                }
                @include until($desktop) {
                    font-size: 210% !important;
                }
                @include from($desktop) {
                    font-size: 240% !important;
                }
            }
        }

        &:first-child {
            font-size: 300%;
        }

        & div:last-of-type {
            margin-left: -30px;
        }
    }

    .icon-logo-FF-Gaming-Group {
        @include until($desktop) {
            font-size: 230%;
        }

        @include from($desktop) {
            font-size: 260%;
        }
    }

    .icon-logo-MGA-cut {
        font-size: 260%;
    }

    .icon-logo-eu-stars {
        @include from($widescreen) {
            margin-left: -50px;
        }
    }

   &.main {
    margin-top: 0;
    @include from(1595px) {
        row-gap: 0.5rem;
    }
    @include until($desktop) {
        row-gap: 0.5rem;
    }

    .column {

        @include until($tablet) {
            i {
                font-size: 200%;
            }
        }
    }

        .icon-logo-paysafecard {
            font-size: 400% !important;
            
            @include until($tablet) {
                font-size: 250% !important;
            }
        }

      .icon-logo-ezeewallet,
      .icon-logo-ecopayz,
      .icon-logo-coinspaid,
      .icon-logo-skrill {
        font-size: 200% !important;

        @include from($widescreen) {
            font-size: 170% !important;
        }

        @include until($tablet) {
            font-size: 130% !important;
        }
        @include until($tablet) {
            font-size: 130% !important;
        }
        @include until(460px) {
            font-size: 100% !important;
        }
      }
      .icon-logo-mifinity {
        font-size: 280% !important;
      }
       .icon-logo-revolut {
        font-size: 160% !important;

        @include until($desktop) {
            font-size: 150% !important;
        }

        @include until($tablet) {
            font-size: 100% !important;
        }
       }
       .icon-logo-cashtocode {
            @include until($fullhd) {
                font-size: 150% !important;
            }
            @include from($fullhd) {
                font-size: 140% !important;
            }
            @include until($tablet) {
                font-size: 80% !important;
            }
            @include until(460px) {
                font-size: 80% !important;
            }
        }
        .icon-logo-flykk {

            
            @include until(390px) {
                font-size: 120% !important;
            }
        }

    .icon-logo-visa,
    .icon-logo-mastercard {
        font-size: 375% !important;
        @include until($tablet) {
            font-size: 300% !important;
        }
    }
   }

    .column {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include until(tablet) {
        font-size: 1.3vw;
    }

    a {
        text-decoration: none;
        display: flex;

        &:hover {
            text-decoration: none;
            color: white;
        }
    }
}

.m-footer-logos-providers {
    padding-bottom: 0px !important;
    max-width: 1840px;
    margin: auto;
    
    &.is-centered {
        justify-content: space-evenly !important;
    }
    .column {
        padding: 0;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include until($tablet) {
            max-height: none;
            min-height: auto;
            height: 80px;
        }
    }

    .image {
        width: 100%;
        max-width: 60%;
        position: initial !important;
        display: flex;
        justify-content: center;
        align-items: center;

        @include until($desktop) {
            min-height: 30px;
            max-width: 80%;
        }
        @include from($desktop) {
            max-width: 70%;
        }

        img {
            position: static;
            height: auto;
            max-width: 110px;
            opacity: 0.7;
        }

        &.is-5by3 {
            padding: 0;
            position: initial !important;
        }
    }
}

.m-social-media-icons {

    display: flex;
    justify-content: center;
    margin-top: $gap;

    @include from($widescreen) {
        justify-content: flex-end;
    }
    .icon {
        border: 1px solid;
        border-radius: 50%;
        color: $grey;
        &:hover {
            color: white;
            text-decoration: none;
        }
    }

    li {
        display: inline-block;
        margin: 5px;
        text-align: center;
    }

    i {
        font-size: 150%;
    }
}

.gamepage-footer-container {
    margin-left: auto;
    margin-right: auto;
    font-size: 0.75rem;
    display: flex;
    
    #section-logo-1, #section-logo-2 { 
        gap: 2rem;
    }
    .footer-text {
        text-align: justify;
    }

    @include until(1170px) {
        display: grid;
        align-items: center;
        justify-items: center;

        grid-template-columns: 50% 50%;
        grid-template-rows: 60px;

        #section-logo-1 {
            grid-column: 1/2;
            grid-row: 1/2;
            width: 100%;
        }
        #section-logo-2 {
            grid-column: 2/3;
            grid-row: 1/2;
            width: 100%;
        }
        #section-text {
            grid-column: 1/3;
            grid-row: 2/3;
            width: 100%;
        }
        .footer-text {
            text-align: center;
        }
    }

    @include until($tablet){
        visibility: hidden;
    }

    .image {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        margin: 5px;
        min-height: 0;
        opacity: 0.9 !important;
    }

    .icon-logo-18 {
        @include from($fullhd) {
            font-size: 350%;
        }
    }

    .icon-logo-MGA-cut {
        font-size: 260%;
    }

   &.main {
    max-width: 1840px;
    justify-content: center;

    @include until(1300px) {
        max-width: 100%;
    }

    @include until(900px) {
        justify-content: center;
    }




    @include from(1595px) {
        row-gap: 0.5rem;
    }
    @include until($desktop) {
        row-gap: 0.5rem;
    }

} 


    &:last-of-type {
        justify-content: space-around;

        @include from($desktop) {
            row-gap: 1rem;
        }

        .brand {
            justify-content: space-between;
            flex-direction: row;
            max-width: 330px;
            flex: 1;

            @include until($tablet) {
                max-width: 100%;
            }

            .column {
                justify-content: center;
                width: auto;
                margin-bottom: 0;
            }
        }

        .column:nth-of-type(4) {
            @include from($desktop) {
                width: 100% !important;
                max-width: 400px;
            }
        }
    }

    .columns{
        align-items: center;
    }
    #section-logo-1 {
        margin-bottom: 0;
    }

    &:nth-last-of-type(-n+1) {
        & > .column:last-child {
            flex-direction: row;
            justify-content: space-around;
            border: 2px solid yellow;
            @include from($tablet) {
                min-width: 340px;
            }

            @include until($desktop) {
                width:100%;
            }
        }
    }
}