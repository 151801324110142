.m-game-provider-modal {
  height: calc(100vh);
  &__content {
    background-color: $dark;
    width:100%;
    min-height: 400px;
    padding: 20px 30px 20px;
    overflow: hidden;
    border-radius: 6px;
    position: fixed;
    top: 10px;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: auto;
    overscroll-behavior: contain;
    height: calc(100% - 75px);
    align-content: flex-start;
    margin-left: -5px;

    &::-webkit-scrollbar {
      width: 0.5em;
    }
     
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 1);
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      outline: 1px solid slategrey;
    }
  }
  .input {
    margin: 5px 0 20px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $primary;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $primary;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $primary;
    }
  }
  .icon {
    position: absolute;
    top: 5px !important;
    left: 5px !important;
  }
  .delete {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .control {
    margin-top: 10px;
  }
}

.m-game-provider-button {
  display: inline-block;
  width: 33.3333%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.1rem;
  @include until(560px) {
    width: 50%;
  }
  &__content,
  &__empty {
    padding: 10px;
    margin: 5px;
    background-color: rgba(33, 38, 51, 0.5);
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    img {
      max-height: 100%;
      max-width: 65%;
    }
    &:hover {
      background-color: $primary;
      color: black;
      img {
        filter: invert(1);
      }
    }
  }
}

.m-game-provider-button.is-active {
  .m-game-provider-button__content {
    border: 1px solid $primary;
  }
}