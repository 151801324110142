.m-game-grid {

    padding: $column-gap*2 0 $column-gap*2;
    max-width: 1915px;
    margin: auto;

    &__container {
        padding: 20px 0;
        z-index: 1;

        h2 {
            font-weigth: 800 !important;

            &.mt-10-games {
                margin-top: 5rem;

                @media screen and (max-width: 980px) {
                    margin-top: 2.5rem;
                }
            }

            &.mt-16-games {
                margin-top: 8rem;

                @media screen and (max-width: 980px) {
                    margin-top: 4rem;
                }
            }

            @include until($desktop) {
                font-size: 1.25rem !important;
            }
        }

        > .container {
            min-height: 38px;

            a.has-text-primary {
                text-decoration: underline !important;
            }
        }

        &.container.is-fluid {
            margin:0 auto;
            padding: 1.7rem 0;

            @include until ($desktop) {
                .notification-active & {
                    margin-top: 62px;
                  }
            }
        }

        &:first-of-type {
            border-top: 0px solid;
        }
    }

    @include until($tablet) {
        padding: 0;
    }

    &-item {
        position: relative;
        overflow: hidden;

        &:not(.featured-grid) {
            .m-game-grid-buttons {
                .button {
                    @include until(800px) {
                        font-size: 12px;
                    }
        
                    @include until(600px)  {
                        font-size: 10px;
                    }
        
        
                    @include until(420px) {
                        font-size: 12px;
                    }
        
                    @include until(350px) {
                        font-size: 10px;
                    }
                }
            }
        }

        figure {
            background-color: darken($brand-dark-grey, 1%);
            padding-bottom: 69.5%;
            position: relative;
            overflow: hidden;
            margin: 0 !important;
            height: 100%;
        }

        img {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;

            &.is-jackpot {
                top: 0;
                width: 40%;
                max-width: 130px;
                height: auto;
            }
        }

        span img {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            transition: transform ease 250ms;
            transform: scale(1);
        }

        span {
            vertical-align: top;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            transition: transform ease 250ms;
            transform: scale(1);
        }

        i {
            margin-right: 10px;
        }
    }

    &-buttons {
        position: absolute;
        top: $column-gap;
        bottom: $column-gap;
        left: $column-gap;
        right: $column-gap;
        text-align: center;
        justify-content: center;
        align-content: space-around;
        flex-direction: column;
        transition: ease 500ms;

        & > *:not(.favorited) {
            opacity: 0;
        }

        &:hover,
        .on {
            transition: ease 500ms;
            background-color: rgba(0, 0, 0, 0.7);
            backdrop-filter: blur(5px);

            & > a,* {
                opacity: 1;
            }

            &:after {
                right: 100%;
                transition: right ease 500ms;
            }
        }

            &:after {
                content:'';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }


        .button {
            align-self: center;
            margin: $gap;
            transition: pointer-events ease 500ms;
            pointer-events: auto;
            text-transform: uppercase;

            @include until($fullhd) {
                padding: 0 10px;
            }

            &-play-for-real {
                border-radius: 50px;
                text-transform: uppercase !important;
                font-weight: 800;
                padding: 0px 1.5rem !important;
            }
            &-play-for-free {
                color: $teritary;
                text-transform: lowercase !important;
                position: absolute;
                margin-left: auto;
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto;
                bottom: 2px;
                background: transparent;
                border: none;
                font-weight: 600;
                font-size: 13px;
                cursor: pointer;

                &:hover {
                    color: $primary;
                    text-decoration: underline !important;
                    opacity: 0.7;
                }
            }
        }
    }
}

.m-game-grid-item__shadow
{
    box-shadow:0 1px 4px #887696, 0 0 40px #887696 inset;
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    bottom: 10px;
    z-index: -1;
}

.m-game-grid-item__shadow:before, .m-game-grid-item__shadow:after
{
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 10px 10px rgba(36, 1, 68, 0.5);
    top: 50%;
    bottom: -3px;
    left: 0;
    right: 0;
    border-radius: 100px / 10px;
}

.m-game-grid-item__label {
    background-color: $primary;
    color: white;
    text-align: center;
    position: absolute;
    top: 10px;
    right: -28px;
    width: 100px;
    box-shadow: 0 0 10px $primary;
    transform: rotate(45deg);
    font-weight: 700;
    text-transform: uppercase;

    &.is-jackpot {
        top: 15px;
        right: auto;
        width: 170px;
        box-shadow: 0 0 10px #000;
        transform: rotate(-35deg);
        right: auto;
        left: -42px;
        background-color: #000;
        line-height: 30px;
    }

}

.m-game-grid-item__provider {
    padding: 0;
    img {
        position: absolute;
        width: 40%;
        max-width: 80px;
        height: auto;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);

        &.BFGames {
            width: auto;
            max-height: 50px;

            @include until($desktop) {
                max-height: 30px;
            }
        }
    }
}

.m-game-grid-item__shadow:after
{
    right:10px;
    left:auto;
    transform:skew(8deg) rotate(3deg);
}


.featured-grid {
  grid-row: span 2;
  grid-column: span 2;
}

.vertical-grid {
    grid-row: span 2;
  }

.parent-grid {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: row dense;
}

@media only screen and (max-width: 1130px) {
  .parent-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .parent-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .parent-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.button-play-for-free {
    @include until(800px) {
        font-size: 15px !important;
    }

    @include until(500px) {
        font-size: 13px !important;
    }

    @include until(400px) {
        font-size: 0.7rem !important;
    }

    @include until(300px) {
        font-size: 0.65rem !important;
    }
}

.button-play-for-real {
    @include until(800px) {
        font-size: 15px !important;
    }

    @include until(500px) {
        font-size: 13px !important;
    }

    @include until(400px) {
        font-size: 0.7rem !important;
    }

    @include until(300px) {
        font-size: 0.6rem !important;
    }
}

.favorite__buttons {
    position: absolute;
    top: .3rem;
    right: .3rem;
    background: transparent;
    border: none;
    z-index: 10;

    span {
        &.button-favorite {
            & svg {
                cursor: pointer;
                &:hover {
                    fill: darken(#ffdc16, 10%);
                    animation: heartbeat 1.5s ease;
                }
            }
        }

        &.button-favorite,
        &.button-favorited {
            position: initial;

            & svg {
                cursor: pointer;
                &:hover {
                    fill: darken(#fff, 10%);
                    animation: heartbeat 1.5s ease;
                }
            }
        }
    }

    @keyframes heartbeat {
                0% {
                    transform: scale(1.0);
                }
                15% {
                    transform: scale(1.1);
                }
                30% {
                    transform: scale(1.0);
                }
                50% {
                    transform: scale(1.1);
                }
                65% {
                    transform: scale(1.0);
                }
            }
}