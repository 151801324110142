.m-steps {
    margin-left: -64px;
    margin-right: -64px;
  
    @include until($tablet) {
      margin-left: -24px;
      margin-right: -34px;
      margin-top: 10px;
    }
  
    &.is-medium {
      .step-item .step-details {
        margin-top: 0;
        padding: 0;
        padding-bottom: 0.2em;
        margin: 0;
        height: 100%;
      }
  
      .step-item:not(:first-child)::before {
        top: 3.7rem;
      }
    }
  
    .step-item {
      min-width: 25%;
    }
  
    .step-done {
      display: none;
    }
  
    .is-completed {
      .step-number {
        display: none;
      }
  
      .step-done {
        display: inline;
      }
    }
  }
  
  .step-content {
    display: none;
    &.is-active {
      display: block;
    }
    .notification {
      background-color: $brand-dark;
    }
  }
  
  .m-steps-v2 {
    border-radius: 8px;
    overflow: hidden;
  
    &__container {
      width: 100%;
      display: none;
      &.is-active {
        display: block;
      }
    }
    .modal-card-head .columns {
      width: auto;
    }
    .step-item {
      background-color: $dull-green;
      color: white;
      font-size: 24px;
      padding: 5px 30px;
      height: 146px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid $dark;
      position: relative;
  
      @include until($tablet) {
        padding: 5px 20px;
      }
  
      @include until(600px) {
        font-size: 14px;
      }
  
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 32px solid transparent;
        border-left: 22px solid $dark;
        border-bottom: 32px solid transparent;
        position: absolute;
        right: -22px;
        z-index: 1;
  
        @include until(600px) {
          border-top: 18px solid transparent;
          border-left: 10px solid $dark;
          border-bottom: 18px solid transparent;
          right: -10px;
        }
  
      }
  
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-left: 20px solid $dull-green;
        border-bottom: 30px solid transparent;
        position: absolute;
        right: -20px;
        z-index: 1;
  
        @include until(600px) {
          border-top: 35px solid transparent;
          border-left: 20px solid #274623;
          border-bottom: 35px solid transparent;
          right: -9px;
        }
      }
      
      &:last-child {
        border-color: $dull-green;
        &:before,
        &:after {
          display: none;
        }
      }
     
      > span {
        font-weight: 700;
        display: flex;
        @include until(600px) {
          flex-direction: column;
        }
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
  
      &.is-active {
        background-color: $success;
        &:after {
          border-left: 20px solid $success;
        }
      }
    }
    .m-step-title {
      line-height: 1.21;
  
      p {
        font-size: 16px;
      }
      
      @include until(600px) {
        font-size: 16px;
        p {
          font-size: 13px;
        }
      }
    }
    .m-step-number {
      font-size: 58px;
      margin-right: 20px;
      @include until($tablet) {
        margin-right: 10px;
      }
      @include until(600px) {
        font-size: 28px;
        margin-right: 0;
      }
    }
  }
