.m-landing-page-hero-banner {
  background-image: url(/images/bg-landing-page-hero-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1920px;
  margin: auto;
  aspect-ratio: 1920/1000;
  overflow: hidden;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }

  &__title {
    font-size: clamp(0.3em, 1vw, 1.3em);
    font-weight: 700;
    color: #fff;
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: pulse-bonus 3s ease-in;
  }

  &__small {
    font-size: 3em;
    font-weight: 700;
    padding-right: 0.2em;
  }

  &__bonus {
    display: inline-flex;
    align-items: center;
    margin-left: -9em;
    margin-bottom: -1em;
    aspect-ratio: 401/169;

    &-amount {
      font-size: 11em;
      font-weight: 800;
      line-height: 1;
      aspect-ratio: 320/170;
      display: block;
    }
  }

  &__spins {
    display: flex;
    text-align: left;
    align-items: center;
    &-amount {
      font-size: 7em;
      line-height: 1;
      font-weight: 800;
      display: block;
      aspect-ratio: 271/113;
    }

    &-text {
      font-size: 2.5em;
      line-height: 1.1;
      padding-left: 0.2em;
    }
  }

  .button {
    font-size: clamp(1em, 2vw, 2em);
    height: 2em;
    color: #000;
    margin-bottom: 1em;
    animation: pulse-button 4s infinite;

    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  .is-small-link,
  .is-small-link a {
    font-size: clamp(0.75em, 1.5vw, 1em);
  }
}

.m-landing-page-video {
  width: 100%;
  max-width: 1920px;
  margin: -80px auto 0;
  @include until($tablet) {
    margin: 0 auto;
  }
  video {
    width: 100%;
    max-width: 1118px;
    margin: auto;
  }
}

.m-landing-page-section-curve-mobile {
  position: absolute;
  bottom: -68px;
  width: 1920px;
  z-index: 3;
  pointer-events: none;

  @include from(710px) {
    display: none;
  }
}

.landing-page-wrapper {
  overflow: hidden;
}

.m-thumbnail-carousel {
  .m-landing-page-section-curve-mobile {
    transform: translateX(-22%) translateY(-26%) scale(1.5) scaleX(-1);
  }
}

@keyframes pulse-button {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 221, 25, 0.7);
  }
  
  40% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 221, 25, 0.7);
  }

  80% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(255, 221, 25, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 221, 25, 0);
  }
}

@keyframes pulse-bonus {
  0% {
    transform: scale(0.5);
    text-shadow: 0 0 20px rgb(245, 245, 245);
  }
  
  75% {
    transform: scale(1.1);
    text-shadow: 0 0 40px rgb(245, 245, 245);
  }

  100% {
    transform: scale(1);
    text-shadow: 0 0 40px rgb(0, 0, 0);
  }
}