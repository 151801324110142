.m-promo-news-banner-area {
    margin: $gap/2;
}

.m-promo-news-banner {
    margin: $gap 0;
    background-position-x: left;
    background-repeat: no-repeat;
    overflow: hidden;
    @include from($tablet) {
        min-height: 470px;
        padding-top: 50px;
        overflow: visible;
    }
    @include until($tablet) {
        position: relative;

        > .columns {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
    min-height: 300px;

    .m-blog-layout &,
    .m-blog-article-layout & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .columns {
            margin-bottom: $gap*7;
        }
    }

}


.m-promo-news-banner-title {

    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;

    .m-blog-layout &,
    .m-blog-article-layout & {
        @include from($tablet) {
            max-width: fit-content;
        }
    }

    @include from($tablet) {
        padding-left: 25px;
        margin-left: -$gap;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-top: 10px;
        display: inline-block;

    }

    .content & {
        color: $brand-dark;
    }
}

.m-promo-news-banner-sub-title {
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.m-promo-tall-banner {
    padding: $gap*3 $gap*4;
    min-height: 740px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-size: cover;

}

.m-promo-blog-title-banner {
    min-height: 200px;
    background-color: $primary;
    background-position: right 0 top;
    background-size: 1196px 100%;
    padding: 0 30px 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin: $gap/2 $gap/2 $gap*4;

    @include until($tablet) {
        padding: 0 $gap*2 $gap*2;
    }
}

@media only screen and (min-width: 320px) {
    .m-promo-news-banner {
        background-position: center;
        background-size: cover;
    }
}

@media only screen and (min-width: 480px) {
    .m-promo-news-banner {
        background-position: center;
    }
}

@media only screen and (min-width: 710px) {
    .m-promo-news-banner {
        background-position: center;
    }
}

@media only screen and (min-width: 980px) {
    .m-promo-news-banner {
        background-position: left center;
    }
}

.m-promo-video-area {
  position: relative;
  overflow: hidden;
  min-height: 470px;

  video {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .m-promo-news-banner-title,
  .columns {
    position: relative;
    z-index: 2;
  }
}