// =========================================================================
// Bulma Variables
// =========================================================================

$fa-font-path: '../webfonts';
$family-sans-serif: 'Nunito', sans-serif;
$font-title-primary: 'Nunito', sans-serif;
$brand-yellow: #ffdd19;
$brand-dark: #111011;
$whamoo-dark: #111011;
$whamoo-grey: #1f2b3a;
$brand-grey: #1f2b3a;
$brand-mid-grey: #252525;
$brand-dark-grey: #191f20;
$brand-dark-greyer: #2a2a2a;
$scheme-main: $brand-dark;
$primary: $brand-yellow;
$primary-opaque: rgba(255, 221, 25, 0.9);
$success: #00A550;
$blue: #07a0d9;
$blue-light: #e8f0fd;
$blue-dark: #170b25;
$grey-light: #a6b4c1;
$grey-dark: #8a939c;
$grey-panel: #e2e2e3;
$grey-panel-highlight: #ececed;
$dark: $brand-dark;
$dark-green: #239a04;
$dull-green: #274623;
$light-green: #4ef722;
$free: #00a9b4;
$info: $free;
$link: $brand-dark;
$gold: #9a7a63;
$facebook: #39569c;
$twitter: #1da1f2;
$youtube: #ff0000;
$content-bg-color: #191e21;
$content-text-color: #fafafa;
$brand-menu-bg: #1A1C1D;
$form-text-color: #656565;
$teritary: #9e9e9e;
// =========================================================================
// Breakpoints
// =========================================================================
$fullhd: 1940px;
$tablet: 710px;

// =========================================================================
// Columns
// =========================================================================

$column-gap: 0.2rem;

// =========================================================================
// Layout Rules
// =========================================================================

$container-max-width: 1920px;
$container-width: 1920px;
$gap: 10px;

// =========================================================================
// Navbar Variables
// =========================================================================

$navbar-background-color: transparent;
$navbar-item-color: white;
$navbar-item-active-color: white;
$navbar-item-active-background-color: $brand-grey;
$navbar-item-hover-color: $navbar-item-active-color;
$navbar-item-hover-background-color: $navbar-item-active-background-color;
$navbar-breakpoint: 999999px;
$navbar-burger-color: $primary;
// =========================================================================
// Footer Rules
// =========================================================================
$footer-background-color: $brand-dark;
$footer-color: #a09fa0;

// =========================================================================
// Button Variables
// =========================================================================

$button-padding-horizontal: 2rem;
$button-padding-vertical: 0rem;
$button-border-width: 2px;

// =========================================================================
// Modal Variables
// =========================================================================

$modal-z: 999999;
$modal-content-width: 800px;
$modal-card-head-radius: 8px;
$modal-card-foot-radius: 8px;
$modal-card-head-padding: 50px;
$modal-card-body-padding: 20px 50px;
$modal-card-head-background-color: $brand-dark;
$modal-card-foot-background-color:$brand-dark;
// =========================================================================
// Steps Variables
// =========================================================================

$steps-default-color: $grey-light;
$steps-maker-default-color: $grey-light;
$steps-completed-color: $success;
$steps-active-color: $success;

// =========================================================================
// Accordion Variables
// =========================================================================

//$accordion-radius: $radius !default

$accordion-header-background-color: $primary;
$accordion-header-color: $brand-dark;
$accordion-header-padding: 0.75em;
$accordion-header-radius: 0;

// =========================================================================
// Form Variables
// =========================================================================
$label-color: $grey-light;
$label-weight: 500;
$input-disabled-background-color: $brand-mid-grey;
$input-disabled-border-color: $brand-mid-grey;
$input-disabled-color: $form-text-color;
$input-color: $form-text-color;
$input-focus-border-color: $primary;
$textarea-color: $form-text-color;

// =========================================================================
// Table Variables
// =========================================================================
$table-background-color: transparent;
$table-color: $content-text-color;
$table-head-cell-color: $content-text-color;
$table-cell-heading-color: $content-text-color;
$table-cell-border: $grey-light;
$table-striped-row-even-background-color: $brand-grey;
$table-head-cell-color: $grey-light;

$card-background-color: $brand-dark;
$card-footer-border-top: $brand-grey;

$calendar-today-color: $primary;

$switch-background: $brand-dark;
$switch-paddle-background: $primary;